import React from "react"
import { PageProps } from "gatsby"
import i18n from "i18next"
import SEO from "../../../components/layout/seo"
import { useCustomTranslation } from "../../../i18n-hook"
import translationFr from "../../../locales/fr/asia/japan/food-in-japan.json"
import translationEn from "../../../locales/en/asia/japan/food-in-japan.json"
import { JapanBlogLayout, JapanHeadline, JapanTitle } from "../../../components/core/japan/japan"
import { Introduction, MenuArticle, SectionContent, SubTitleTransport } from "../../../components/core/section"
import { Conclusion } from "../../../components/core/conclusion"
import { Divider } from "../../../components/core/divider"
import { Quote } from "../../../components/core/quote"
import HomeImgUrl from "../../../images/asia/japan/food-in-japan/food-in-japan-main.jpg"
import {
  Anchor,
  GroupOfImages,
  ImageAsLandscape,
  ImageAsLandscapeOnTheLeft,
  ImageAsLandscapeOnTheRight,
  ImageAsPortrait,
  TwoImagesSameSizeOrToGroup,
} from "../../../components/images/layout"
import { SharedCardJapanImages } from "../../../components/images/asia/japan/shared-card-japan-images"
import { FoodInJapanImages } from "../../../components/images/asia/japan/food-in-japan"
import { FakeList } from "../../../components/core/title"
import { Comments } from "../../../components/core/comments"
import styled from "@emotion/styled"

const namespace = "asia/japan/food-in-japan"
const id = "food-in-japan"
i18n.addResourceBundle("fr", namespace, translationFr)
i18n.addResourceBundle("en", namespace, translationEn)

const Barbecue = () => (
  <svg id="Flat" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
    <path d="m352 432h-192a8 8 0 0 1 0-16h192a8 8 0 0 1 0 16z" fill="#95573a" />
    <path d="m176 360-56 112h32l56-112z" fill="#d13330" />
    <path d="m336 360 56 112h-32l-56-112z" fill="#d13330" />
    <path d="m24.6 216s-23.941 176.037 231.4 176 231.4-176 231.4-176z" fill="#eb423f" />
    <ellipse cx="256" cy="216" fill="#95573a" rx="232" ry="48" />
    <g fill="#dadcde">
      <path d="m256 272a8 8 0 0 1 -8-8v-96a8 8 0 0 1 16 0v96a8 8 0 0 1 -8 8z" />
      <path d="m208 270.97a8 8 0 0 1 -8-8v-93.94a8 8 0 1 1 16 0v93.94a8 8 0 0 1 -8 8z" />
      <path d="m160 267.71a8 8 0 0 1 -8-8v-87.42a8 8 0 1 1 16 0v87.42a8 8 0 0 1 -8 8z" />
      <path d="m112 261.63a8 8 0 0 1 -8-8v-75.26a8 8 0 0 1 16 0v75.26a8 8 0 0 1 -8 8z" />
      <path d="m64 250.96a8 8 0 0 1 -8-8v-53.92a8 8 0 0 1 16 0v53.92a8 8 0 0 1 -8 8z" />
      <path d="m448 250.96a8 8 0 0 1 -8-8v-53.92a8 8 0 1 1 16 0v53.92a8 8 0 0 1 -8 8z" />
      <path d="m400 261.63a8 8 0 0 1 -8-8v-75.26a8 8 0 0 1 16 0v75.26a8 8 0 0 1 -8 8z" />
      <path d="m352 267.71a8 8 0 0 1 -8-8v-87.42a8 8 0 1 1 16 0v87.42a8 8 0 0 1 -8 8z" />
      <path d="m304 270.97a8 8 0 0 1 -8-8v-93.94a8 8 0 1 1 16 0v93.94a8 8 0 0 1 -8 8z" />
    </g>
    <path
      d="m304 336h-96a8 8 0 0 1 -8-8v-32a8 8 0 0 1 16 0v24h80v-24a8 8 0 0 1 16 0v32a8 8 0 0 1 -8 8z"
      fill="#95573a"
    />
    <circle cx="88" cy="88" fill="#eb423f" r="32" />
    <circle cx="288" cy="88" fill="#b1bd70" r="32" />
    <path d="m120 48h48v80h-48z" fill="#95573a" />
    <path d="m208 48a40 40 0 0 0 0 80z" fill="#f0dfce" />
    <path d="m256 48v80l-48-30v-18z" fill="#ffd33a" />
    <path d="m456 96h-120a8 8 0 0 1 0-16h120a8 8 0 0 1 0 16z" fill="#dadcde" />
    <path d="m320 40h32v96h-32z" fill="#95573a" />
    <path
      d="m256 272c-62.5 0-121.333-5.052-165.67-14.225-50.017-10.348-74.33-24.013-74.33-41.775s24.313-31.427 74.33-41.775c44.337-9.173 103.17-14.225 165.67-14.225s121.333 5.052 165.67 14.225c50.017 10.348 74.33 24.013 74.33 41.775s-24.313 31.427-74.33 41.775c-44.337 9.173-103.17 14.225-165.67 14.225zm0-96c-61.441 0-119.126 4.934-162.428 13.893-50.51 10.45-61.572 22.389-61.572 26.107s11.062 15.657 61.572 26.107c43.302 8.959 100.987 13.893 162.428 13.893s119.126-4.934 162.428-13.893c50.51-10.45 61.572-22.389 61.572-26.107s-11.062-15.657-61.572-26.107c-43.302-8.959-100.987-13.893-162.428-13.893z"
      fill="#e9eef2"
    />
  </svg>
)

const Donburi = () => (
  <svg id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path
          clipRule="evenodd"
          d="m346.079 209.159-77.943 146.851 20.212 10.724c2.246 1.185 4.996.268 6.243-1.916l84.063-149.077c-12.209-.35-23.231-2.946-32.575-6.582zm131.337-178.201-21.881-11.59-6.82-3.616-78.365 147.656c3.194-1.69-2.421 7.623 1.288 7.088 1.288 1.288 7.417 3.101 7.469 3.091 1.525-.227 4.595-1.803 6.181-1.803 7.798 0 15.247-9.416 20.892-4.831l73.153-129.761c1.246-2.216.329-5.049-1.917-6.234z"
          fill="#e29d7b"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m319.274 192.057-73.287 147.006c-1.123 2.267-.319 5.048 1.926 6.233l20.222 10.714 77.943-146.852c-12.083-4.728-21.365-11.229-26.804-17.101zm122.611-179.93-21.881-11.59c-2.246-1.185-5.11-.35-6.233 1.916l-66.714 133.892-12.414 24.848c3.163-1.01 6.459 11.363 9.951 11.363 5.996 0 9.086-1.803 13.907 1.03.587.329 2.019 1.748 2.586 2.129.783.515 5.954-2.459 6.686-1.872 1.659-1.298.711-9.467 2.575-10.436l78.366-147.656z"
          fill="#e29d7b"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m314.067 180.595c-14.573-13.321-33.964-21.473-55.277-21.473-7.981 0-15.903 1.152-23.559 3.456-8.868 2.658-17.086 6.764-24.416 12.081 11.913 6.882 20.781 18.313 24.239 32.284 18.83-10.19 51.079 14.916 65.209 33.259h28.821 11.558c-.03-5.169-.562-10.249-1.537-15.152-2.601-13.173-8.336-25.195-16.435-35.267-1.182-1.447-2.394-2.865-3.665-4.253-1.568-1.715-3.224-3.34-4.938-4.935z"
          fill="#f37c7c"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m288.35 211.875c-7.479-7.621-17.884-12.376-29.56-12.376-8.838 0-17.056 2.688-23.736 7.443-.059.059-6.917 41.234-9.075 45.546l74.284-12.287c-.089-3.928-.709-7.739-1.803-11.313-1.952-6.498-5.469-12.287-10.11-17.013z"
          fill="#ffd772"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m59.262 185.144c.148-.148.266-.295.414-.443-.148.148-.266.295-.414.443zm41.354-44.689c-17.322-10.958-44.014-16.039-73.988-4.519-4.316 1.654-9.962 2.658-2.069 8.773 14.396 11.135 25.924 25.284 34.703 40.436.148-.148.266-.295.414-.443 8.75-9.718 32.279-7.68 45.256-9.009l5.173-8.891c.029-10.87-13.923-19.022-9.489-26.347z"
          fill="#4fc9af"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m257.549 90.655c-13.45-1.625-57.109 15.979-76.648 47.614 5.262 7.68-.03 29.005 0 40.968 7.745.797 23.53-8.3 29.914-4.578 9.311-6.764 20.13-11.608 31.836-13.941 9.903-16.275 17.145-34.174 23.47-56.002 3.754-12.968-4.286-13.529-8.572-14.061z"
          fill="#4fc9af"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m365.087 166.831c-5.587-4.49-12.711-7.177-20.455-7.177-1.153 0-2.306.059-3.429.177-12.445 1.3-22.791 9.54-27.136 20.764 16.76 15.359 9.43 49.12 9.636 71.893l51.464-2.836 28.939-9.452c14.514 0 16.731-7.739 24.83-15.891 4.936-4.933 3.252-21.208 9.075-24.545-4.523-7.325-11.883-12.76-20.544-14.739-.739-3.338-2.01-6.469-3.725-9.334-5.705-9.599-16.199-16.039-28.17-16.039-1.123 0-2.247.059-3.311.177-6.473.652-12.356 3.162-17.174 7.002z"
          fill="#a7e9a6"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m438.011 199.765c-5.823 3.338-11.262 7.473-16.199 12.435-8.099 8.123-13.982 17.693-17.706 28.001l34.821 12.287 34.171-12.287c.946-.325 1.98.089 2.956.236 7.863-13.055 11.558-28.09 11.203-43.39-.177-6.616-1.094-7.532-7.715-7.65-14.602-.266-28.939 3.19-41.531 10.368z"
          fill="#4fc9af"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m457.284 338.736-190.187-20.499-200.031 20.499c18.002 56.593 59.947 102.523 113.982 125.857l89.507 23.718 72.776-23.718c54.006-23.334 95.98-69.264 113.953-125.857z"
          fill="#fbc7b8"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m343.331 464.593h-162.283v41.765c0 3.101 2.542 5.642 5.646 5.642h150.962c3.133 0 5.675-2.54 5.675-5.642z"
          fill="#f37c7c"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m344.632 159.654c-1.153 0-2.306.059-3.429.177-12.445 1.3-22.791 9.54-27.136 20.764 1.064.975 2.069 1.979 3.074 2.983 12.858-7.561 32.9-8.92 45.552 5.021 4.789-3.84 10.701-6.38 17.145-7.03 1.094-.089-3.252-.148 3.311-.148 11.735 0 20.721 11.844 20.16 23.009 5.735 1.3 14.159 4.106 18.297 7.975l.207-.207c4.936-4.962 10.375-9.097 16.199-12.435-4.523-7.325-11.883-12.76-20.544-14.739-.739-3.338-2.01-6.469-3.725-9.334-5.705-9.599-16.199-16.039-28.17-16.039-1.123 0-2.247.059-3.311.177-6.474.65-12.356 3.16-17.174 7-5.588-4.487-12.712-7.174-20.456-7.174z"
          fill="#7fdbae"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m463.255 197.431c.296 14.857-2.306 29.537-7.833 42.77h17.677c1.005 0 1.98.089 2.956.236 7.863-13.055 11.558-28.09 11.203-43.39-.177-6.616-1.094-7.532-7.715-7.65-5.823-.089-11.617.384-17.263 1.477.709 1.3.886 3.338.975 6.557z"
          fill="#26bb9e"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m257.549 90.655c-1.862-.236-4.316-.089-7.183.414 0 1.654-.266 3.633-.975 6.085-6.326 21.798-13.568 39.698-23.47 56.002-3.754.738-36.506 14.591-36.506 14.591 7.745.798 15.016 3.19 21.401 6.912 9.311-6.764 20.13-11.608 31.836-13.941 9.903-16.275 17.145-34.174 23.47-56.002 3.753-12.968-4.287-13.529-8.573-14.061z"
          fill="#26bb9e"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m59.262 185.144c.148-.148.266-.295.414-.443-.148.148-.266.295-.414.443zm41.354-44.689c-5.055-3.19-10.937-5.907-17.44-7.827-3.34 6.705-5.262 14.68-5.291 23.954-11.174 1.152-21.313 5.612-29.294 12.524 3.931 5.139 7.479 10.515 10.671 16.039l.414-.443c8.513-9.393 20.426-15.566 33.846-16.954-.001-10.841 2.66-19.968 7.094-27.293z"
          fill="#26bb9e"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m314.067 180.595c-14.573-13.321-33.964-21.473-55.277-21.473-7.981 0-15.903 1.152-23.559 3.456-8.868 2.658-17.086 6.764-24.416 12.081.414.236.828.473 1.242.738.562-.177 1.153-.354 1.714-.532 7.656-2.304 15.578-3.456 23.559-3.456 21.313 0 40.704 8.152 55.277 21.473 1.714 1.595 3.37 3.22 4.966 4.933 1.241 1.388 2.453 2.806 3.636 4.253 8.099 10.072 13.834 22.094 16.435 35.267.177.945.355 1.92.503 2.865h10.937 11.558c-.03-5.169-.562-10.249-1.537-15.152-2.601-13.173-8.336-25.195-16.435-35.267-1.182-1.447-2.394-2.865-3.665-4.253-1.568-1.713-3.224-3.338-4.938-4.933z"
          fill="#ee6161"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m343.331 464.593h-48.862v14.178 27.588c0 3.101-2.542 5.642-5.646 5.642h48.833c3.133 0 5.675-2.54 5.675-5.642z"
          fill="#ee6161"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m408.421 338.736c-17.972 56.593-59.918 102.523-113.953 125.857h48.862c54.006-23.334 95.981-69.264 113.953-125.857z"
          fill="#fbb49e"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m57.518 276.797c0 21.591 3.34 42.386 9.548 61.939h390.218c6.208-19.553 9.548-40.348 9.548-61.939l-202.455-13.557z"
          fill="#f37c7c"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m466.832 276.797c-15.312 0-30.653 0-45.965 0h-2.867v.354c-.059 21.473-3.399 42.149-9.577 61.585h48.862c6.206-19.553 9.547-40.347 9.547-61.939z"
          fill="#ee6161"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m141.467 118.213c-15.992.059-31.954 7.561-40.852 22.241-4.434 7.325-7.094 16.452-7.094 27.292-13.627 1.418-25.717 7.739-34.26 17.397-8.07 9.156-12.977 24.575-12.977 34.942s-1.892 18.726 4.966 20.115l93.025 18.786 87.379-18.786c3.133-6.262 4.966-13.085 4.966-20.115 0-4.578-.532-8.979-1.567-13.144-3.458-13.971-12.326-25.402-24.239-32.284-6.385-3.722-13.657-6.114-21.401-6.912 0-11.962-3.252-21.798-8.513-29.478-9.104-13.231-24.268-19.995-39.433-20.054z"
          fill="#a7e9a6"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m227.782 190.49c0-.03-.03-.03-.03-.03-3.045-4.49-6.385-7.945-9.311-10.427-.03-.03-.03-.03-.059-.059 3.577 3.043 6.74 6.588 9.4 10.516zm-15.637-15.034c-.089-.059-.177-.118-.266-.177.088.059.177.118.266.177zm-1.33-.798c.355.207.709.414 1.064.62-.651-.383-1.005-.59-1.064-.62zm-29.915-36.389c-9.104-13.233-24.269-19.997-39.433-20.056-11.676.03-23.352 4.076-32.279 11.962 3.34-.709 6.769-1.034 10.169-1.063 15.164.059 30.328 6.823 39.433 20.056 5.262 7.68 8.513 17.515 8.513 29.507 7.774.768 15.016 3.16 21.401 6.882.03.177 18.534 9.156 24.239 32.284 1.892 7.621 2.128 15.064.532 22.359h3.843 11.676 2.66c.296-.561.562-1.152.828-1.713 2.69-5.966 4.05-12.022 4.138-18.195 0-.059 0-.148 0-.207.059-2.57-.118-5.169-.503-7.798-.177-1.093-.355-2.215-.591-3.338-.118-.561-.236-1.093-.384-1.654 0-.03 0-.059-.03-.089 0-.089-.03-.177-.059-.266-1.389-5.671-3.695-10.929-6.74-15.655-.177-.266-.355-.532-.532-.798-2.66-3.928-5.823-7.473-9.4-10.515 0 0-.03 0-.03-.03-.236-.207-.473-.384-.709-.591 0 0 0 0-.03 0 0 0 0 0 0-.03-2.306-1.861-4.227-3.131-5.469-3.869-.443-.266-.887-.532-1.33-.798-6.385-3.722-13.657-6.114-21.401-6.912 0-11.933-3.222-21.769-8.513-29.448.059.059.089.118.148.207-.058-.085-.117-.173-.147-.232z"
          fill="#7fdbae"
          fillRule="evenodd"
        />
      </g>
      <path
        d="m178.007 197.33c-2.111-3.708-6.827-5.003-10.538-2.891l-18.276 10.407v-20.204c0-4.268-3.459-7.726-7.726-7.726s-7.726 3.459-7.726 7.726v20.204l-18.276-10.407c-3.706-2.111-8.426-.818-10.538 2.891-2.111 3.708-.817 8.426 2.891 10.537l25.923 14.762v13.909c0 4.268 3.459 7.726 7.726 7.726s7.726-3.459 7.726-7.726v-13.909l25.922-14.762c3.709-2.112 5.003-6.83 2.892-10.537z"
        fill="#26bb9e"
      />
      <g>
        <path
          d="m455.535 19.369-74.781 140.65c-3.709.536-7.211 1.7-10.405 3.389-1.865.968-3.606 2.122-5.264 3.42-.731-.587-1.483-1.143-2.266-1.658-.567-.381-1.143-.742-1.731-1.071l80.797-151.972 6.83 3.626z"
          fill="#ce8b6b"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m51.251 240.201c-10.05 0-18.297 8.241-18.297 18.283 0 10.072 8.247 18.313 18.297 18.313h6.267 409.314 6.267c10.08 0 18.298-8.241 18.298-18.313 0-9.038-6.681-16.6-15.342-18.047-.975-.148-1.951-.236-2.956-.236-140.617 0-281.232 0-421.848 0z"
          fill="#365e7d"
          fillRule="evenodd"
        />
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m424.236 276.797h42.596 6.267c10.08 0 18.298-8.241 18.298-18.313 0-9.038-6.681-16.6-15.342-18.047-.975-.148-1.951-.236-2.956-.236h-48.803c.975 0 1.951.089 2.897.236 8.691 1.447 15.342 9.009 15.342 18.047-.002 10.072-8.219 18.313-18.299 18.313z"
          fill="#2b4d66"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
)

const Gyozas = () => (
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g id="flat">
      <path
        d="m384.65 381.535a25.782 25.782 0 0 0 23.35-25.265c0-.09 0-.18 0-.27a40 40 0 0 0 -25.469-37.266 39.994 39.994 0 0 0 -38.531-50.734c-1.1 0-2.192.057-3.272.145a40.007 40.007 0 0 0 -55.518-19.458 39.969 39.969 0 0 0 -58.42 0 40.007 40.007 0 0 0 -55.518 19.458c-1.08-.088-2.169-.145-3.272-.145a39.994 39.994 0 0 0 -38.531 50.734 40 40 0 0 0 -25.469 37.266 26.916 26.916 0 0 0 .954 6.443 25.747 25.747 0 0 0 22.469 19.1l47.041 4.7a135.323 135.323 0 0 1 34.038 7.942 135.275 135.275 0 0 0 95 0 135.272 135.272 0 0 1 34.037-7.942z"
        fill="#ee9d0d"
      />
      <path
        d="m175.993 347a7.973 7.973 0 0 1 -3.786-.958l-13-7a8 8 0 0 1 7.586-14.088l13 7a8 8 0 0 1 -3.8 15.046z"
        fill="#d78e0c"
      />
      <path
        d="m199.994 312a7.967 7.967 0 0 1 -4.794-1.6l-12-9a8 8 0 1 1 9.6-12.8l12 9a8 8 0 0 1 -4.806 14.4z"
        fill="#d78e0c"
      />
      <path
        d="m237.383 295.013a8 8 0 0 1 -7.03-4.171l-6-11a8 8 0 1 1 14.047-7.662l6 11a8 8 0 0 1 -7.016 11.833z"
        fill="#d78e0c"
      />
      <path
        d="m336.011 347a8 8 0 0 1 -3.8-15.046l13-7a8 8 0 1 1 7.586 14.088l-13 7a7.976 7.976 0 0 1 -3.786.958z"
        fill="#d78e0c"
      />
      <path
        d="m274.621 295.013a8 8 0 0 1 -7.021-11.833l6-11a8 8 0 1 1 14.047 7.662l-6 11a8 8 0 0 1 -7.026 4.171z"
        fill="#d78e0c"
      />
      <path d="m488 64-224 104-16-48 240-96z" fill="#d6a27f" />
      <path
        d="m384.65 169.535a25.782 25.782 0 0 0 23.35-25.265c0-.09 0-.18 0-.27a40 40 0 0 0 -25.469-37.266 39.994 39.994 0 0 0 -38.531-50.734c-1.1 0-2.192.057-3.272.145a40.007 40.007 0 0 0 -55.518-19.458 39.969 39.969 0 0 0 -58.42 0 40.007 40.007 0 0 0 -55.518 19.458c-1.08-.088-2.172-.145-3.272-.145a39.994 39.994 0 0 0 -38.531 50.734 40 40 0 0 0 -25.469 37.266 26.916 26.916 0 0 0 .954 6.443 25.747 25.747 0 0 0 22.469 19.1l47.041 4.7a135.323 135.323 0 0 1 34.038 7.942 135.275 135.275 0 0 0 95 0 135.272 135.272 0 0 1 34.037-7.942z"
        fill="#eeb436"
      />
      <path
        d="m384.65 466.734a25.782 25.782 0 0 0 23.35-25.265c0-.09 0-.18 0-.27a40 40 0 0 0 -25.469-37.266 39.994 39.994 0 0 0 -38.531-50.733c-1.1 0-2.192.056-3.272.144a40.01 40.01 0 0 0 -55.518-19.458 39.972 39.972 0 0 0 -58.42 0 40.01 40.01 0 0 0 -55.518 19.458c-1.08-.088-2.169-.144-3.272-.144a39.994 39.994 0 0 0 -38.531 50.734 40 40 0 0 0 -25.469 37.266 26.916 26.916 0 0 0 .954 6.443 25.748 25.748 0 0 0 22.469 19.1l47.041 4.7a135.28 135.28 0 0 1 34.038 7.942 135.275 135.275 0 0 0 95 0 135.272 135.272 0 0 1 34.037-7.942z"
        fill="#eeb436"
      />
      <g fill="#d78e0c">
        <path d="m175.993 432.2a7.961 7.961 0 0 1 -3.786-.958l-13-7a8 8 0 0 1 7.586-14.088l13 7a8 8 0 0 1 -3.8 15.046z" />
        <path d="m199.994 397.2a7.966 7.966 0 0 1 -4.794-1.6l-12-9a8 8 0 0 1 9.6-12.8l12 9a8 8 0 0 1 -4.806 14.4z" />
        <path d="m237.383 380.212a8 8 0 0 1 -7.03-4.171l-6-11a8 8 0 1 1 14.046-7.662l6 11a8 8 0 0 1 -7.016 11.833z" />
        <path d="m336.011 432.2a8 8 0 0 1 -3.8-15.046l13-7a8 8 0 1 1 7.586 14.088l-13 7a7.964 7.964 0 0 1 -3.786.958z" />
        <path d="m312.006 398.2a8 8 0 0 1 -4.806-14.4l12-9a8 8 0 0 1 9.6 12.8l-12 9a7.964 7.964 0 0 1 -4.794 1.6z" />
        <path d="m274.621 380.212a8 8 0 0 1 -7.017-11.833l6-11a8 8 0 0 1 14.047 7.662l-6 11a8 8 0 0 1 -7.03 4.171z" />
        <path d="m256 465.171a84.013 84.013 0 0 1 -26.53-4.277 8 8 0 1 1 5.06-15.18 68.319 68.319 0 0 0 42.94 0 8 8 0 1 1 5.06 15.18 84.013 84.013 0 0 1 -26.53 4.277z" />
        <path d="m256 167.972a84 84 0 0 1 -26.529-4.277 8 8 0 1 1 5.058-15.179 68.326 68.326 0 0 0 42.942 0 8 8 0 1 1 5.058 15.179 84 84 0 0 1 -26.529 4.277z" />
        <path d="m312.006 313a8 8 0 0 1 -4.806-14.4l12-9a8 8 0 1 1 9.6 12.8l-12 9a7.965 7.965 0 0 1 -4.794 1.6z" />
        <path d="m175.993 135a7.973 7.973 0 0 1 -3.786-.958l-13-7a8 8 0 0 1 7.586-14.088l13 7a8 8 0 0 1 -3.8 15.046z" />
        <path d="m199.994 100a7.967 7.967 0 0 1 -4.794-1.6l-12-9a8 8 0 1 1 9.6-12.8l12 9a8 8 0 0 1 -4.806 14.4z" />
        <path d="m237.383 83.013a8 8 0 0 1 -7.03-4.171l-6-11a8 8 0 1 1 14.047-7.662l6 11a8 8 0 0 1 -7.016 11.833z" />
        <path d="m336.011 135a8 8 0 0 1 -3.8-15.046l13-7a8 8 0 1 1 7.586 14.088l-13 7a7.976 7.976 0 0 1 -3.786.958z" />
        <path d="m312.006 101a8 8 0 0 1 -4.806-14.4l12-9a8 8 0 0 1 9.6 12.8l-12 9a7.965 7.965 0 0 1 -4.794 1.6z" />
        <path d="m274.621 83.013a8 8 0 0 1 -7.021-11.833l6-11a8 8 0 1 1 14.047 7.662l-6 11a8 8 0 0 1 -7.026 4.171z" />
      </g>
      <path d="m488 104-224 96-16-32 240-104z" fill="#f6bb92" />
    </g>
  </svg>
)

const Ramen = () => (
  <svg id="Capa_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m447.355 287.38h-220.495v-5.195c0-58.113 47.11-105.222 105.222-105.222h10.051c58.113 0 105.222 47.11 105.222 105.222z"
        fill="#fec165"
      />
      <g>
        <path
          d="m415.612 266.871c-3.281 0-6.323-2.109-7.358-5.405-9.122-29.048-35.694-48.565-66.12-48.565h-10.051c-30.426 0-56.998 19.516-66.12 48.565-1.276 4.065-5.603 6.33-9.671 5.049-4.065-1.277-6.325-5.606-5.049-9.671 5.286-16.833 16.029-31.952 30.249-42.57 14.72-10.991 32.214-16.801 50.591-16.801h10.051c18.377 0 35.871 5.81 50.591 16.801 14.22 10.618 24.963 25.736 30.249 42.57 1.277 4.065-.984 8.395-5.049 9.671-.769.242-1.548.356-2.313.356z"
          fill="#fea613"
        />
      </g>
      <g>
        <path
          d="m297.857 266.871c-.766 0-1.544-.115-2.313-.357-4.065-1.277-6.325-5.606-5.049-9.671 6.083-19.372 23.806-32.387 44.1-32.387h5.026c20.294 0 38.016 13.015 44.1 32.387 1.277 4.064-.983 8.395-5.049 9.671-4.065 1.281-8.395-.983-9.671-5.049-4.054-12.908-15.861-21.581-29.38-21.581h-5.026c-13.52 0-25.327 8.673-29.38 21.581-1.035 3.297-4.078 5.406-7.358 5.406z"
          fill="#fea613"
        />
      </g>
      <path
        d="m167.147 74.542 324.528-7.132c2.307-.051 4.205 1.806 4.205 4.113v26.464c0 2.272-1.842 4.114-4.114 4.114h-324.316c-7.611 0-13.781-6.17-13.781-13.781-.001-7.493 5.986-13.613 13.478-13.778z"
        fill="#5a5a5a"
      />
      <path
        d="m167.147 12.809 324.528-7.132c2.307-.051 4.205 1.806 4.205 4.113v26.464c0 2.272-1.842 4.114-4.114 4.114h-324.316c-7.611 0-13.781-6.17-13.781-13.781-.001-7.494 5.986-13.614 13.478-13.778z"
        fill="#5a5a5a"
      />
      <path
        d="m491.675 67.41-24.953.548v18.366c0 2.272-1.842 4.114-4.114 4.114h-308.76c1.021 6.603 6.713 11.663 13.602 11.663h324.315c2.272 0 4.114-1.842 4.114-4.114v-26.463c0-2.308-1.897-4.165-4.204-4.114z"
        fill="#444"
      />
      <path
        d="m491.675 5.676-24.953.548v18.366c0 2.273-1.842 4.114-4.114 4.114h-308.76c1.021 6.603 6.713 11.663 13.602 11.663h324.315c2.272 0 4.114-1.842 4.114-4.114v-26.463c0-2.308-1.897-4.165-4.204-4.114z"
        fill="#444"
      />
      <path
        d="m146.152 252.155v-236.726c0-8.521 6.908-15.429 15.429-15.429 8.521 0 15.429 6.908 15.429 15.429v236.726c0 8.521-6.908 15.429-15.429 15.429-8.521 0-15.429-6.908-15.429-15.429z"
        fill="#fece85"
      />
      <path
        d="m177.01 252.155v-236.726c0-8.521 6.908-15.429 15.429-15.429 8.521 0 15.429 6.908 15.429 15.429v236.726c0 8.521-6.908 15.429-15.429 15.429-8.521 0-15.429-6.908-15.429-15.429z"
        fill="#fec165"
      />
      <path
        d="m207.868 252.155v-236.726c0-8.521 6.908-15.429 15.429-15.429 8.521 0 15.429 6.908 15.429 15.429v236.726c0 8.521-6.908 15.429-15.429 15.429-8.521 0-15.429-6.908-15.429-15.429z"
        fill="#fece85"
      />
      <path
        d="m223.297 0c-3.25 0-6.26 1.01-8.747 2.725 4.034 2.783 6.682 7.432 6.682 12.704v236.726c0 5.272-2.648 9.92-6.682 12.703 2.487 1.716 5.498 2.726 8.747 2.726 8.521 0 15.429-6.908 15.429-15.429v-236.726c0-8.521-6.908-15.429-15.429-15.429z"
        fill="#fec165"
      />
      <path
        d="m194.41 166.072c7.156 0 11.716 15.861 18.174 17.957 6.692 2.173 19.691-7.902 25.261-3.848 5.626 4.094.039 19.597 4.133 25.223 4.054 5.57 20.544 5.023 22.717 11.715 2.097 6.457-11.498 15.679-11.498 22.835s13.594 16.379 11.498 22.836c-2.173 6.692-18.663 6.145-22.717 11.715-4.094 5.626 1.493 21.128-4.133 25.223-5.57 4.054-18.569-6.021-25.261-3.848-6.457 2.097-11.017 17.957-18.173 17.957s-11.716-15.861-18.174-17.957c-6.692-2.173-19.691 7.902-25.261 3.848-5.626-4.094-.039-19.597-4.133-25.223-4.054-5.57-20.544-5.023-22.717-11.715-2.097-6.457 11.498-15.679 11.498-22.835s-13.594-16.379-11.498-22.836c2.173-6.692 18.663-6.145 22.717-11.715 4.094-5.626-1.493-21.128 4.133-25.223 5.57-4.054 18.569 6.021 25.261 3.848 6.457-2.097 11.017-17.957 18.173-17.957z"
        fill="#fdd5d5"
      />
      <g>
        <path
          d="m264.695 217.12c-2.173-6.692-18.663-6.145-22.717-11.716-4.094-5.626 1.493-21.128-4.133-25.223-4.556-3.315-14.078 2.816-21.057 3.972 1.411 6.288-1.357 16.824 1.864 21.251 4.054 5.57 20.544 5.024 22.717 11.716 2.097 6.457-11.498 15.679-11.498 22.835s13.594 16.379 11.498 22.836c-2.173 6.692-18.663 6.145-22.717 11.716-3.221 4.426-.453 14.963-1.864 21.25 6.979 1.157 16.501 7.288 21.057 3.972 5.626-4.094.039-19.597 4.133-25.223 4.054-5.57 20.544-5.024 22.717-11.716 2.097-6.457-11.498-15.68-11.498-22.836 0-7.155 13.594-16.377 11.498-22.834z"
          fill="#f9c6c6"
        />
      </g>
      <g>
        <path
          d="m173.807 280.777c-1.974 0-3.949-.753-5.455-2.259-18.668-18.669-18.668-49.044 0-67.713 24.307-24.304 66.14-7.787 66.14 23.632 0 28.311-31.584 41.831-49.781 23.631-16.813-16.809.305-44.777 10.91-34.176 3.013 3.014 3.013 7.897 0 10.911-3.407 3.406-3.407 8.949 0 12.355 8.601 8.605 23.442 1.667 23.442-12.721 0-17.733-25.219-27.307-39.801-12.721-12.652 12.652-12.652 33.239 0 45.891 3.013 3.014 3.013 7.897 0 10.911-1.507 1.505-3.481 2.259-5.455 2.259z"
          fill="#f79595"
        />
      </g>
      <path
        d="m34.42 254.897c-14.941-55.76 3.779-109.224 41.812-119.415s80.977 26.75 95.918 82.51c11.294 42.151-9.033 84.383-45.543 95.729-.799.259-1.605.505-2.422.724s-1.638.408-2.46.584c-37.291 8.429-76.01-17.981-87.305-60.132z"
        fill="#dff6fd"
      />
      <ellipse
        cx="104.945"
        cy="242.641"
        fill="#fea613"
        rx="35.47"
        ry="39.998"
        transform="matrix(.966 -.259 .259 .966 -59.224 35.43)"
      />
      <path
        d="m172.149 217.992c-14.941-55.76-57.885-92.701-95.918-82.51-.009.003-.017.006-.026.008 29.833 7.509 58.145 39.372 69.702 82.503 11.294 42.151-9.033 84.382-45.543 95.729-.799.259-1.605.505-2.422.724s-1.638.409-2.46.584c-.327.074-.655.125-.983.193 8.879 1.833 18.093 1.87 27.224-.193.822-.175 1.642-.365 2.46-.584.817-.219 1.623-.465 2.422-.724 36.511-11.348 56.839-53.579 45.544-95.73z"
        fill="#c8effe"
      />
      <path
        d="m335.077 503.172v-19.663c56.605-21.098 101.027-68.004 118.215-127.215h-427.057c17.484 60.222 62.602 106.497 118.199 127.215v19.663c0 4.875 3.952 8.828 8.828 8.828h172.988c4.875 0 8.827-3.953 8.827-8.828z"
        fill="#5a5a5a"
      />
      <path
        d="m16.121 290.437v-27.597c0-2.078 1.685-3.763 3.763-3.763h439.743c2.078 0 3.763 1.685 3.763 3.763v27.597z"
        fill="#5a5a5a"
      />
      <path
        d="m415.387 356.295c-17.188 59.21-61.61 106.117-118.215 127.214v19.664c0 4.875-3.952 8.828-8.828 8.828h37.905c4.875 0 8.828-3.953 8.828-8.828v-19.664c56.605-21.098 101.027-68.004 118.215-127.214z"
        fill="#444"
      />
      <path
        d="m459.627 259.076h-37.905c2.078 0 3.763 1.685 3.763 3.763v27.598h37.905v-27.597c0-2.079-1.685-3.764-3.763-3.764z"
        fill="#444"
      />
      <path
        d="m26.235 356.294h427.057c3.108-10.708 5.33-21.817 6.568-33.25l3.53-32.608h-447.269l3.53 32.608c1.237 11.436 3.475 22.54 6.584 33.25z"
        fill="#e94444"
      />
      <path
        d="m425.485 290.437-3.53 32.607c-1.238 11.433-3.46 22.542-6.568 33.25h37.905c3.108-10.709 5.33-21.817 6.568-33.25l3.53-32.607z"
        fill="#d83131"
      />
    </g>
  </svg>
)

const Soba = () => (
  <svg id="Capa_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <g>
          <path
            d="m335 280.306 1.747-1c7.109 0 12.873-5.763 12.873-12.873 0-7.109-5.763-12.873-12.873-12.873h-168.374l-1 2.483v22.263l.171 2z"
            fill="#afc939"
          />
          <path
            d="m55.312 279.306 1.688 1h111.373v-26.745h-113.061c-7.109 0-12.872 5.763-12.872 12.873s5.763 12.872 12.872 12.872z"
            fill="#9ab130"
          />
          <path
            d="m197.029 230.162-1-2.346h-116.935c-7.109 0-12.873 5.763-12.873 12.873s5.763 12.873 12.873 12.873h117.936v-23.4z"
            fill="#728323"
          />
          <g>
            <path
              d="m318.627 253.56h-122.598v-25.745h122.598c7.109 0 12.873 5.763 12.873 12.873 0 7.109-5.763 12.872-12.873 12.872z"
              fill="#9ab130"
            />
          </g>
          <path
            d="m144.229 204.46-1-2.39h-47.148c-7.109 0-12.873 5.763-12.873 12.873 0 7.109 5.763 12.873 12.873 12.873h48.148z"
            fill="#9ab130"
          />
          <path d="m236.414 202.07h-93.185v25.745h93.185l1-1v-22.314z" fill="#afc939" />
          <g>
            <path
              d="m309.983 214.943c0-7.109-5.763-12.873-12.872-12.873h-60.696v25.745h60.696c7.109 0 12.872-5.763 12.872-12.872z"
              fill="#728323"
            />
          </g>
          <path
            d="m482.979 69.651h-344.856c-2.631 0-4.763 2.133-4.763 4.763v20.982l8.478 1h332.648l8.494-1c7.109 0 12.873-5.763 12.873-12.873-.001-7.109-5.765-12.872-12.874-12.872z"
            fill="#fac600"
          />
        </g>
        <path
          d="m332.887 381.274c7.589 0 15.054 1.297 22.188 3.857.11.04.225.059.338.059.245 0 .485-.09.673-.26.273-.249.388-.628.296-.987l-16.233-63.665c-.318-1.25-.047-2.552.744-3.571.792-1.019 1.986-1.604 3.276-1.604h44.273c.553 0 1-.448 1-1v-33.844c-.914-.601-2.006-.954-3.182-.954h-380.46c-3.203 0-5.8 2.597-5.8 5.8v98.595c0 3.203 2.597 5.8 5.8 5.8h295.273c9.722-5.392 20.629-8.226 31.814-8.226z"
          fill="#fdb97e"
        />
        <path
          d="m332.887 381.274c6.724 0 13.349 1.023 19.734 3.036v-15.113l-12.473-48.919c-.318-1.25-.047-2.552.744-3.571.792-1.019 1.986-1.604 3.276-1.604h8.453v-35.798h-313.183v110.195h261.635c9.722-5.392 20.629-8.226 31.814-8.226z"
          fill="#fea95c"
        />
        <g>
          <path d="m39.438 279.306h42.432v110.194h-42.432z" fill="#fe9738" />
        </g>
        <g>
          <g>
            <path d="m63.797 306.603h-24.359v15h24.359c4.143 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" fill="#fea95c" />
            <path
              d="m328.263 321.603h12.223l-.338-1.326c-.318-1.25-.047-2.552.744-3.571.792-1.019 1.986-1.604 3.276-1.604h8.453v-8.5h-24.358c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.501 7.5 7.501z"
              fill="#fe9738"
            />
          </g>
          <g>
            <path d="m63.797 347.202h-24.359v15h24.359c4.143 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" fill="#fea95c" />
            <path
              d="m328.263 362.202h22.575l-3.825-15h-18.75c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5z"
              fill="#fe9738"
            />
          </g>
        </g>
      </g>
      <g>
        <path d="m465.677 315.103v-248.033c0-5.261-4.265-9.526-9.526-9.526h-16.219v257.56h25.745z" fill="#9ab130" />
        <path d="m414.187 57.544h25.745v257.56h-25.745z" fill="#afc939" />
        <path d="m414.187 315.103v-257.559h-16.219c-5.261 0-9.527 4.265-9.527 9.526v248.033z" fill="#9ab130" />
      </g>
      <path
        d="m506.848 314.104h-162.68c-3.36 0-5.819 3.166-4.989 6.421l16.233 63.665.823 1.362c4.309 1.639 8.457 3.734 12.375 6.272l37.101 24.034c.162.105.351.161.544.161h27.823c.583 0 1.145.247 1.539.676.395.429.593 1.008.544 1.588-1.669 19.724-18.493 35.173-38.302 35.173v1h75.325c2.651 0 4.965-1.798 5.62-4.367l33.035-129.564c.829-3.256-1.631-6.421-4.991-6.421z"
        fill="#a44f3e"
      />
      <g>
        <path
          d="m355.412 384.19c4.762 1.708 9.377 3.967 13.742 6.794l37.088 24.026-25.728-100.907h-36.345c-3.36 0-5.82 3.166-4.99 6.421z"
          fill="#883f2e"
        />
      </g>
      <g>
        <path
          d="m133.36 95.396v20.982c0 2.631 2.133 4.763 4.763 4.763h344.856c7.109 0 12.873-5.763 12.873-12.873 0-7.109-5.763-12.873-12.873-12.873h-349.619z"
          fill="#fed402"
        />
      </g>
      <g>
        <path d="m407.797 416.018-38.643-25.034c-22.056-14.288-50.447-14.288-72.503 0l-38.644 25.034z" fill="#fff3de" />
        <g>
          <path
            d="m302.092 415.018 37.1-24.034c4.742-3.072 9.778-5.479 14.98-7.23-18.992-6.393-40.209-3.986-57.523 7.23l-37.101 24.034z"
            fill="#ffe5c2"
          />
        </g>
        <g>
          <path
            d="m267.946 454.456c-20.324 0-37.585-15.851-39.298-36.087-.073-.861.218-1.714.804-2.351.585-.637 1.41-.999 2.275-.999h202.351c.865 0 1.69.363 2.275.999.585.637.877 1.489.804 2.351-1.712 20.236-18.974 36.088-39.298 36.088h-129.913z"
            fill="#cbe5e8"
          />
        </g>
        <g>
          <path
            d="m229.452 416.018c-.585.637-.877 1.489-.804 2.351 1.713 20.236 18.974 36.087 39.298 36.087h54.164c-21.776-.005-39.428-17.66-39.428-39.437h-50.955c-.865 0-1.69.362-2.275.999z"
            fill="#a8d3d8"
          />
        </g>
      </g>
    </g>
  </svg>
)

const Sushi = () => (
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <g>
          <path
            clipRule="evenodd"
            d="m35.276 374.33h82.379c.993-.934 1.635-2.249 1.635-3.738v-41.322l-44.946-14.864-40.674 14.864v41.322c0 1.489.613 2.804 1.606 3.738z"
            fill="#e29d7b"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m394.316 374.33h82.379c.993-.934 1.635-2.249 1.635-3.738v-41.322l-42.81-15.551-42.81 15.551v41.322c0 1.489.613 2.804 1.606 3.738z"
            fill="#e29d7b"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m288.458 235.324c0 31.773 25.99 57.792 57.762 57.792s57.762-26.02 57.762-57.792v-39.891c0-30.517-23.975-55.69-53.995-57.646-1.256-.088-2.511-.117-3.767-.117-31.772 0-57.762 25.991-57.762 57.763z"
            fill="#f9f6f6"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m346.22 293.117h48.417 3.387c30.721 0 55.864-25.173 55.864-55.894v-43.687c0-30.721-25.143-55.865-55.864-55.865h-37.32l-10.717.117c42.752 5.898 42.664 156.205-3.767 155.329z"
            fill="#365e7d"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m373.378 137.67c30.721 0 55.893 25.144 55.893 55.865v43.687c0 30.546-24.851 55.573-55.338 55.894h10.834 9.87 3.387c30.721 0 55.864-25.173 55.864-55.894v-43.687c0-30.721-25.143-55.865-55.864-55.865z"
            fill="#2b4d66"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m346.22 174.378c-12.761 0-23.77 9.579-28.998 23.508 32.794 10.25 29.991 31.451 60.244 28.706.642-3.329.993-6.804.993-10.367 0-23.128-14.426-41.847-32.239-41.847z"
            fill="#fbc7b8"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m336.583 176.276c12.323 5.87 21.084 21.23 21.084 39.278 0 .934-.029 1.869-.058 2.803 4.906 3.796 10.776 6.979 19.857 8.235.642-3.329.993-6.804.993-10.367 0-23.129-14.426-41.848-32.239-41.848-3.358.001-6.6.643-9.637 1.899z"
            fill="#fbb49e"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m327.326 140.853c18.368 6.395 32.59 21.844 37.233 40.942 8.41 7.564 13.9 20.15 13.9 34.43 0 3.563-.35 7.038-.993 10.367-2.015 10.192-6.892 18.894-13.491 24.56-3.709 12.966-11.885 24.122-22.719 31.656h37.787c15.039-10.455 24.939-27.86 24.939-47.484v-39.891c0-30.517-23.975-55.69-53.995-57.646-1.256-.088-2.511-.117-3.767-.117-6.6 0-12.966 1.11-18.894 3.183z"
            fill="#e5e4ed"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m313.952 216.226c0 23.099 14.455 41.848 32.268 41.848 15.039 0 27.684-13.375 31.246-31.481-28.443-3.972-25.289-26.575-60.244-28.706-2.102 5.519-3.27 11.739-3.27 18.339z"
            fill="#f37c7c"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m357.609 218.358c-.905 17.522-10.104 32.094-22.544 37.146 3.475 1.665 7.242 2.57 11.155 2.57 15.039 0 27.684-13.375 31.246-31.481-9.082-1.256-14.951-4.439-19.857-8.235z"
            fill="#ee6161"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m175.125 235.324c0 31.773 25.99 57.792 57.762 57.792s57.762-26.02 57.762-57.792v-39.891c0-30.517-23.946-55.69-53.995-57.646-1.226-.088-2.482-.117-3.767-.117-31.772 0-57.762 25.991-57.762 57.763z"
            fill="#f9f6f6"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m232.887 293.117h48.417 3.387c30.75 0 55.893-25.173 55.893-55.894v-43.687c0-30.721-25.143-55.865-55.893-55.865h-37.32l-10.717.117c42.751 5.898 42.693 156.205-3.767 155.329z"
            fill="#365e7d"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m260.044 137.67c30.75 0 55.893 25.144 55.893 55.865v43.687c0 30.546-24.851 55.573-55.309 55.894h10.834 9.841 3.387c30.75 0 55.893-25.173 55.893-55.894v-43.687c0-30.721-25.143-55.865-55.893-55.865z"
            fill="#2b4d66"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m232.887 174.378c-12.732 0-23.741 9.579-28.998 23.508 32.794 10.25 30.02 31.451 60.244 28.706.672-3.329 1.022-6.804 1.022-10.367 0-23.128-14.455-41.847-32.268-41.847z"
            fill="#fbc7b8"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m223.25 176.276c12.323 5.87 21.113 21.23 21.113 39.278 0 .934-.029 1.869-.088 2.803 4.906 3.796 10.776 6.979 19.857 8.235.672-3.329 1.022-6.804 1.022-10.367 0-23.129-14.455-41.848-32.268-41.848-3.358.001-6.599.643-9.636 1.899z"
            fill="#fbb49e"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m213.993 140.853c18.368 6.395 32.59 21.844 37.262 40.942 8.381 7.564 13.9 20.15 13.9 34.43 0 3.563-.35 7.038-1.022 10.367-1.986 10.192-6.892 18.894-13.462 24.56-3.709 12.966-11.885 24.122-22.719 31.656h37.758c15.068-10.455 24.939-27.86 24.939-47.484v-39.891c0-30.517-23.946-55.69-53.995-57.646-1.226-.088-2.482-.117-3.767-.117-6.6 0-12.966 1.11-18.894 3.183z"
            fill="#e5e4ed"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m200.647 216.226c0 23.099 14.426 41.848 32.239 41.848 15.068 0 27.713-13.375 31.246-31.481-28.443-3.972-25.289-26.575-60.244-28.706-2.072 5.519-3.241 11.739-3.241 18.339z"
            fill="#f37c7c"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m244.275 218.358c-.876 17.522-10.104 32.094-22.544 37.146 3.475 1.665 7.242 2.57 11.155 2.57 15.068 0 27.713-13.375 31.246-31.481-9.081-1.256-14.951-4.439-19.857-8.235z"
            fill="#ee6161"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m61.792 235.324c0 31.773 26.019 57.792 57.791 57.792s57.762-26.02 57.762-57.792v-39.891c0-30.517-23.975-55.69-53.995-57.646-1.256-.088-2.511-.117-3.767-.117-31.772 0-57.791 25.991-57.791 57.763z"
            fill="#f9f6f6"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m119.583 293.117h48.388 3.417c30.721 0 55.864-25.173 55.864-55.894v-43.687c0-30.721-25.143-55.865-55.864-55.865h-37.32l-10.717.117c42.75 5.898 42.663 156.205-3.768 155.329z"
            fill="#365e7d"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m146.74 137.67c30.721 0 55.864 25.144 55.864 55.865v43.687c0 30.546-24.822 55.573-55.309 55.894h10.834 9.841 3.417c30.721 0 55.864-25.173 55.864-55.894v-43.687c0-30.721-25.143-55.865-55.864-55.865z"
            fill="#2b4d66"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m119.29 329.27h273.42 85.621 28.501c2.833 0 5.169-2.307 5.169-5.14v-36.182c0-2.833-2.336-5.14-5.169-5.14h-32.736-44.562-136.608-73.881-44.562-136.579-32.764c-2.833 0-5.14 2.307-5.14 5.14v36.182c0 2.833 2.307 5.14 5.14 5.14h28.53z"
            fill="#ffd772"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m478.33 329.27h-37.641-47.979v18.69h49.41c7.534 0 7.271 3.504 7.271 10.425v15.945h27.304c.993-.934 1.635-2.249 1.635-3.738z"
            fill="#ce8b6b"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m119.29 329.27h-37.641-47.979v18.69h49.41c7.534 0 7.271 3.504 7.271 10.425v15.945h27.304c.993-.934 1.635-2.249 1.635-3.738z"
            fill="#ce8b6b"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m119.583 174.378c-12.761 0-23.77 9.579-28.998 23.508 32.765 10.25 29.991 31.451 60.244 28.706.642-3.329.993-6.804.993-10.367 0-23.128-14.426-41.847-32.239-41.847z"
            fill="#fbc7b8"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m109.946 176.276c12.294 5.87 21.084 21.23 21.084 39.278 0 .934-.029 1.869-.058 2.803 4.906 3.796 10.776 6.979 19.857 8.235.642-3.329.993-6.804.993-10.367 0-23.129-14.426-41.848-32.239-41.848-3.359.001-6.6.643-9.637 1.899z"
            fill="#fbb49e"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m100.689 140.853c18.368 6.395 32.56 21.844 37.233 40.942 8.41 7.564 13.9 20.15 13.9 34.43 0 3.563-.35 7.038-.993 10.367-2.015 10.192-6.892 18.894-13.491 24.56-3.709 12.966-11.885 24.122-22.719 31.656h37.787c15.039-10.455 24.939-27.86 24.939-47.484v-39.891c0-30.517-23.975-55.69-53.995-57.646-1.256-.088-2.511-.117-3.767-.117-6.629 0-12.966 1.11-18.894 3.183z"
            fill="#e5e4ed"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m87.314 216.226c0 23.099 14.455 41.848 32.268 41.848 15.039 0 27.684-13.375 31.246-31.481-28.443-3.972-25.318-26.575-60.244-28.706-2.102 5.519-3.27 11.739-3.27 18.339z"
            fill="#f37c7c"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m130.971 218.358c-.905 17.522-10.104 32.094-22.544 37.146 3.475 1.665 7.213 2.57 11.155 2.57 15.039 0 27.684-13.375 31.246-31.481-9.081-1.256-14.951-4.439-19.857-8.235z"
            fill="#ee6161"
            fillRule="evenodd"
          />
        </g>
      </g>
      <g>
        <path
          clipRule="evenodd"
          d="m451.055 287.963v36.153c0 2.833-2.307 5.169-5.14 5.169h32.414 28.501c2.833 0 5.169-2.336 5.169-5.169v-36.153c0-2.833-2.336-5.169-5.169-5.169h-42.343-9.315-9.257c2.833 0 5.14 2.336 5.14 5.169z"
          fill="#fbba58"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
)

const Tempura = () => (
  <svg id="Capa_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <g>
          <path d="m445.813 343.045h-95.498l-.485-.618v34.688h96.468v-34.688z" fill="#fed2a4" />
          <path
            d="m284.806 261.37 65.024 82.675h96.469l65.024-82.675c1.62-2.06.153-5.08-2.468-5.08h-221.582c-2.62 0-4.087 3.021-2.467 5.08z"
            fill="#fff3de"
          />
          <path
            d="m341.358 256.29h-54.085c-2.62 0-4.088 3.021-2.468 5.08l65.024 82.675h54.085l-65.024-82.675c-1.62-2.059-.153-5.08 2.468-5.08z"
            fill="#ffe5c2"
          />
        </g>
        <g>
          <path
            d="m168.125 149.768c2.112-2.231 4.526-4.202 7.218-5.848 4.828-2.95 10.267-4.682 15.86-5.067l-6.938-74.136c-.313-3.344-4.707-4.359-6.455-1.49l-15.651 25.692c-.863 1.417-2.605 2.031-4.166 1.466l-28.293-10.225c-3.159-1.142-5.949 2.402-4.098 5.205z"
            fill="#fe860a"
          />
          <path
            d="m168.125 149.768c2.112-2.231 4.526-4.202 7.218-5.848 3.129-1.912 6.519-3.293 10.037-4.146l-34.246-51.868-21.434-7.746c-3.159-1.142-5.949 2.402-4.098 5.205z"
            fill="#fe7701"
          />
          <g>
            <path
              d="m173.467 203.73c-2.597 2.188-4.815 4.671-6.66 7.355 2.699 2.864 4.893 6.191 6.461 9.917.113.27.339.476.617.564.1.032.203.048.305.048.184 0 .365-.05.525-.149 5.471-3.374 11.758-5.158 18.184-5.158 5.876 0 11.707 1.531 16.862 4.428 9.183 5.16 15.43 14.095 17.141 24.514.043.262.188.496.404.65.171.123.375.188.583.188.054 0 .109-.004.164-.013 1.868-.31 3.773-.466 5.661-.466 5.877 0 11.706 1.529 16.855 4.423 7.08 3.979 12.549 10.359 15.397 17.965.096.256.292.461.543.569.126.054.26.081.394.081s.268-.027.393-.081c4.338-1.854 8.922-2.793 13.626-2.793 3.192 0 6.367.467 9.431 1.344.96-8.012-.655-16.391-5.19-23.81-4.593-7.515-11.426-12.816-19.113-15.588 3.719-10.267 2.916-22.065-3.222-32.107-7.312-11.963-20.293-18.332-33.374-17.776.863-7.682-.717-15.698-5.061-22.805-10.385-16.992-32.58-22.348-49.572-11.963s-22.348 32.58-11.963 49.572c2.792 4.568 6.442 8.285 10.609 11.091z"
              fill="#fea95c"
            />
          </g>
          <g>
            <path
              d="m173.468 203.731c-2.597 2.188-4.815 4.671-6.66 7.355 2.699 2.864 4.893 6.19 6.461 9.917.113.27.339.476.617.564.1.032.203.048.305.048.184 0 .365-.05.525-.149 5.471-3.374 11.758-5.158 18.184-5.158 1.114 0 2.224.073 3.33.181 1.487-3.209 3.44-6.236 5.855-8.964 1.857-2.098 1.66-5.286-.401-7.185-2.397-2.209-4.533-4.782-6.318-7.702-10.385-16.992-5.03-39.187 11.963-49.572.84-.513 1.696-.977 2.56-1.415-11.531-5.813-25.899-5.298-37.371 2.944-14.664 10.536-19.206 30.73-10.518 46.559 2.881 5.253 6.857 9.472 11.468 12.577z"
              fill="#fe9738"
            />
          </g>
        </g>
        <g>
          <path
            d="m113.392 217.616c3.017-5.369 7.412-9.69 12.593-12.642l-40.737-66.299c-1.677-2.73-5.865-1.746-6.151 1.445l-2.564 28.58c-.141 1.577-1.374 2.836-2.947 3.011l-28.519 3.175c-3.184.354-4.078 4.562-1.313 6.18l68.031 39.822c.472-1.107 1.004-2.2 1.607-3.272z"
            fill="#fe860a"
          />
          <path
            d="m113.392 217.616c2.519-4.482 6-8.231 10.093-11.068l-57.978-33.938-20.44 2.276c-3.184.354-4.078 4.562-1.313 6.181l68.031 39.822c.472-1.108 1.004-2.201 1.607-3.273z"
            fill="#fe7701"
          />
          <path
            d="m359.26 292.146c-14.128-7.939-30.62-7.713-44.048-.861-2.355-8.988-8.204-17.048-16.943-21.959-10.006-5.623-21.569-5.867-31.367-1.68-2.834-7.568-8.247-14.217-15.844-18.486-7.323-4.115-15.481-5.345-23.17-4.072-1.687-10.278-7.852-19.724-17.638-25.223-11.658-6.551-25.431-5.806-36.06.751-2.86-6.797-7.909-12.732-14.836-16.624-16.56-9.306-37.528-3.425-46.834 13.135s-3.425 37.528 13.135 46.834c4.451 2.501 9.221 3.896 13.997 4.29-1.172 3.111-1.869 6.295-2.141 9.47 5-2.688 10.636-4.152 16.341-4.152 3.203 0 6.404.452 9.513 1.342 10.126 2.9 18.268 10.151 22.338 19.894.103.245.298.439.543.54.122.05.25.075.379.075.131 0 .263-.026.387-.078 4.254-1.784 8.736-2.689 13.32-2.689 3.2 0 6.396.451 9.501 1.34 7.808 2.237 14.602 7.182 19.129 13.925.152.227.391.381.66.428.057.01.114.015.17.015.213 0 .421-.068.595-.196 5.921-4.38 13.211-6.792 20.527-6.792 3.202 0 6.401.452 9.51 1.342 9.068 2.597 16.507 8.621 20.948 16.962.143.268.398.456.696.513.062.012.125.018.187.018.235 0 .466-.083.648-.238 8.268-7.033 18.767-10.907 29.561-10.907 4.233 0 8.463.597 12.573 1.774 11.709 3.354 21.411 11.067 27.319 21.718s7.314 22.965 3.959 34.674c-.937 3.271-2.232 6.371-3.824 9.273 10.056-3.688 18.903-10.832 24.562-20.902 12.61-22.438 4.643-50.846-17.793-63.454z"
            fill="#fdb97e"
          />
        </g>
        <g>
          <path
            d="m68.882 294.124c2.063-7.204 6.457-13.307 12.208-17.584l-47.855-47.993c-2.262-2.269-6.109-.345-5.651 2.826l4.104 28.4c.226 1.567-.682 3.076-2.172 3.61l-27.016 9.674c-3.016 1.08-2.915 5.38.149 6.317l65.184 19.921c.214-1.734.559-3.462 1.049-5.171z"
            fill="#fe860a"
          />
          <path
            d="m68.882 294.124c1.48-5.166 4.161-9.762 7.677-13.53l-51.315-15.682-22.745 8.144c-3.016 1.08-2.915 5.38.149 6.317l65.184 19.921c.215-1.733.56-3.461 1.05-5.17z"
            fill="#fe7701"
          />
          <path
            d="m91.514 336.384c4.909 1.406 9.871 1.661 14.61.943-2.381 18.601 10.201 36.015 28.867 39.409l.784.38h217.541c1.64-2.989 2.986-6.197 3.964-9.612 7.087-24.741-7.224-50.542-31.965-57.629-15.579-4.463-31.574-.435-43.057 9.333-4.366-8.201-11.918-14.693-21.556-17.454-11.034-3.161-22.341-.729-30.907 5.607-4.505-6.709-11.307-11.929-19.684-14.329-8.076-2.313-16.297-1.626-23.484 1.388-4.015-9.61-12.194-17.378-22.985-20.469-12.856-3.683-26.084.223-34.912 9.056-4.352-5.953-10.635-10.562-18.273-12.75-18.261-5.231-37.305 5.332-42.536 23.593-5.231 18.259 5.332 37.303 23.593 42.534z"
            fill="#fea95c"
          />
          <path
            d="m89.933 335.89c5.42 1.833 10.943 2.232 16.19 1.436-2.381 18.601 10.201 36.015 28.867 39.409l.784.38h54.566l-22.52-10.902c-16.842-3.063-28.731-17.539-29.144-34.003-.07-2.78-2.32-5-5.1-5.018-4.008-.026-8.097-.758-12.112-2.293-15.674-5.993-24.799-22.796-21.303-39.208 1.325-6.218 4.218-11.633 8.147-15.97-18.058-3.953-36.321 7.154-40.767 25.562-4.143 17.16 5.67 34.951 22.392 40.607z"
            fill="#fe9738"
          />
        </g>
        <g fill="#fdb97e">
          <circle cx="246.213" cy="336.869" r="7.5" />
          <circle cx="308.024" cy="343.05" r="7.5" />
          <circle cx="276.089" cy="354.382" r="7.5" />
          <circle cx="196.765" cy="343.05" r="7.5" />
          <circle cx="159.678" cy="309.054" r="7.5" />
        </g>
        <path
          d="m417.404 450.451h-322.808c-38.872 0-74.16-22.709-90.265-58.087l-3.825-8.403c-1.672-3.673 1.013-7.846 5.049-7.846h500.892c4.036 0 6.721 4.173 5.049 7.846l-3.825 8.403c-16.106 35.379-51.395 58.087-90.267 58.087z"
          fill="#678d98"
        />
        <path
          d="m87.26 392.364-3.825-8.403c-1.672-3.673 1.013-7.846 5.049-7.846h-82.93c-4.036 0-6.721 4.173-5.049 7.846l3.825 8.403c16.105 35.379 51.393 58.087 90.265 58.087h82.93c-38.872 0-74.16-22.708-90.265-58.087z"
          fill="#537983"
        />
      </g>
      <circle cx="232.821" cy="271.968" fill="#fed2a4" r="7.5" />
      <circle cx="199.855" cy="246.213" fill="#fed2a4" r="7.5" />
      <circle cx="200.885" cy="174.101" fill="#fdb97e" r="7.5" />
      <circle cx="233.851" cy="203.976" fill="#fdb97e" r="7.5" />
      <circle cx="160.708" cy="247.243" fill="#fed2a4" r="7.5" />
    </g>
  </svg>
)

const Tonkatsu = () => (
  <svg id="Capa_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle cx="256" cy="256" fill="#dff6fd" r="256" />
      <path
        d="m355.43 20.029c76.166 44.354 127.368 126.878 127.368 221.37 0 141.385-114.615 256-256 256-35.263 0-68.86-7.132-99.43-20.029 37.798 22.011 81.739 34.63 128.632 34.63 141.385 0 256-114.615 256-256 0-106.121-64.573-197.159-156.57-235.971z"
        fill="#c8effe"
      />
      <path
        d="m436.285 279.734c1.029-7.857 5.663-15.601 5.663-23.734s-4.634-15.877-5.663-23.733c-1.052-8.037 1.408-16.713-.651-24.391-2.093-7.805-8.58-14.082-11.64-21.444-3.094-7.443-2.978-16.464-6.984-23.378-4.033-6.96-11.926-11.344-16.806-17.69-4.896-6.366-7.108-15.117-12.778-20.787s-14.421-7.882-20.787-12.778c-6.346-4.881-10.73-12.773-17.69-16.806-6.914-4.007-15.936-3.891-23.379-6.985-7.362-3.06-13.639-9.548-21.444-11.641-7.678-2.059-16.355.401-24.392-.651-7.857-1.029-15.601-5.662-23.734-5.662s-15.877 4.634-23.734 5.662c-1.681.22-3.39.282-5.111.268v360.034c1.722-.014 3.431.048 5.111.268 7.857 1.029 15.601 5.662 23.734 5.662s15.877-4.634 23.734-5.662c8.037-1.052 16.713 1.407 24.391-.651 7.805-2.093 14.082-8.58 21.444-11.64 7.443-3.094 16.464-2.978 23.378-6.984 6.96-4.033 11.344-11.926 17.69-16.806 6.366-4.896 15.116-7.108 20.786-12.778s7.883-14.421 12.778-20.787c4.881-6.346 12.773-10.729 16.807-17.69 4.007-6.914 3.891-15.936 6.985-23.379 3.06-7.362 9.548-13.639 11.641-21.444 2.059-7.68-.401-16.356.651-24.393z"
        fill="#ebebeb"
      />
      <path
        d="m245.869 92.371c0-.078 0-.156 0-.234.033-10.911-9.636-19.28-20.397-17.475-86.917 14.582-153.359 90.341-153.359 181.338 0 91.598 67.322 167.761 155.084 181.625 12.261 1.937 22.507-9.149 19.762-21.255-.343-1.512-.667-3.078-.968-4.702-7.254-39.082 14.302-41.065 14.302-80.815 0-39.747-14.423-39.747-14.423-79.495 0-39.746 14.423-39.746 14.423-79.493-.002-39.747-14.424-39.747-14.424-79.494z"
        fill="#c86d36"
      />
      <path
        d="m196.326 184.23h-30.592c-3.414 0-6.181-2.767-6.181-6.181v-30.592c0-3.414 2.767-6.181 6.181-6.181h30.592c3.414 0 6.181 2.767 6.181 6.181v30.592c0 3.414-2.767 6.181-6.181 6.181z"
        fill="#9e5528"
      />
      <path
        d="m175.163 260.585h30.592c3.414 0 6.181 2.767 6.181 6.181v30.592c0 3.414-2.767 6.181-6.181 6.181h-30.592c-3.414 0-6.181-2.767-6.181-6.181v-30.592c0-3.413 2.767-6.181 6.181-6.181z"
        fill="#fdb441"
      />
      <circle cx="130.175" cy="231.732" fill="#22b27f" r="15.453" />
      <circle cx="159.37" cy="355.271" fill="#f47c7c" r="15.453" />
      <path
        d="m436.285 279.734c1.029-7.857 5.663-15.601 5.663-23.734s-4.634-15.877-5.663-23.733c-1.052-8.037 1.408-16.713-.651-24.391-2.093-7.805-8.58-14.082-11.64-21.444-3.094-7.443-2.978-16.464-6.984-23.378-4.033-6.96-11.926-11.344-16.806-17.69-4.896-6.366-7.108-15.117-12.778-20.787s-14.421-7.882-20.787-12.778c-6.346-4.881-10.73-12.773-17.69-16.806-6.914-4.007-15.936-3.891-23.379-6.985-7.362-3.06-13.639-9.548-21.444-11.641-7.678-2.059-16.355.401-24.392-.651-7.857-1.029-15.601-5.662-23.734-5.662-5.479 0-10.782 2.102-16.061 3.789 14.83 4.738 21.728-.246 32.064 2.525 7.805 2.093 14.082 8.58 21.444 11.641 7.443 3.094 16.465 2.978 23.379 6.985 6.96 4.033 11.344 11.926 17.69 16.806 6.366 4.896 15.117 7.108 20.787 12.778s7.882 14.421 12.778 20.787c4.881 6.346 12.773 10.73 16.806 17.69 4.007 6.914 3.891 15.936 6.984 23.378 3.06 7.362 9.548 13.639 11.64 21.444 2.059 7.678-.401 16.355.651 24.391 1.029 7.857 5.663 15.601 5.663 23.733 0 8.133-4.634 15.877-5.663 23.734-1.052 8.037 1.408 16.713-.651 24.391-2.093 7.805-8.581 14.082-11.641 21.444-3.094 7.443-2.978 16.465-6.985 23.379-4.033 6.96-11.926 11.344-16.807 17.69-4.896 6.366-7.108 15.117-12.778 20.787s-14.421 7.882-20.786 12.778c-6.346 4.881-10.73 12.773-17.69 16.806-6.914 4.007-15.935 3.89-23.378 6.984-7.362 3.06-13.639 9.547-21.444 11.64-10.287 2.758-17.284-2.196-32.064 2.525 5.279 1.687 10.582 3.789 16.061 3.789 8.133 0 15.877-4.634 23.734-5.662 8.037-1.052 16.713 1.407 24.391-.651 7.805-2.093 14.082-8.58 21.444-11.64 7.443-3.094 16.464-2.978 23.378-6.984 6.96-4.033 11.344-11.926 17.69-16.806 6.366-4.896 15.116-7.108 20.786-12.778s7.883-14.421 12.778-20.787c4.881-6.346 12.773-10.729 16.807-17.69 4.007-6.914 3.891-15.936 6.985-23.379 3.06-7.362 9.548-13.639 11.641-21.444 2.06-7.68-.4-16.356.652-24.393z"
        fill="#cecece"
      />
      <path
        d="m245.869 251.358c0-39.746 14.423-39.746 14.423-79.493s-14.423-39.747-14.423-79.494c0-.078 0-.156.001-.234.033-10.911-9.636-19.28-20.397-17.475-2.754.462-5.486.987-8.198 1.57 2.738 27.288 13.815 31.597 13.815 66.431 0 39.746-14.423 39.746-14.423 79.493 0 39.748 14.423 39.748 14.423 79.495 0 39.749-21.555 41.733-14.302 80.815.301 1.624.625 3.19.968 4.702 2.745 12.106-7.501 23.192-19.762 21.255-32.081-5.068-61.425-18.469-85.754-37.897 28.039 35.12 68.649 59.784 114.955 67.099 12.261 1.937 22.507-9.149 19.762-21.255-.343-1.512-.667-3.078-.968-4.702-7.254-39.082 14.302-41.065 14.302-80.815 0-39.747-14.422-39.747-14.422-79.495z"
        fill="#ac5e2e"
      />
      <g fill="#cecece">
        <ellipse cx="340.125" cy="327.048" rx="13.392" ry="16.498" />
        <ellipse cx="368.385" cy="272.467" rx="13.392" ry="16.498" />
        <ellipse cx="309.064" cy="273.302" rx="13.392" ry="16.498" />
        <ellipse cx="297.383" cy="377.609" rx="13.392" ry="16.498" />
      </g>
    </g>
  </svg>
)

const Udon = () => (
  <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m1.75 64h124.5a0 0 0 0 1 0 0v22.438a30 30 0 0 1 -30 30h-64.5a30 30 0 0 1 -30-30v-22.438a0 0 0 0 1 0 0z"
        fill="#fff9e2"
      />
      <path
        d="m62.139 101.456-1.948 1.024a4 4 0 0 1 -5.8-4.217l.369-2.163a4 4 0 0 0 -1.151-3.54l-1.575-1.536a4 4 0 0 1 2.217-6.824l2.177-.316a4 4 0 0 0 3.011-2.188l.974-1.973a4 4 0 0 1 7.174 0l.974 1.973a4 4 0 0 0 3.011 2.188l2.177.316a4 4 0 0 1 2.217 6.823l-1.575 1.536a4 4 0 0 0 -1.151 3.541l.372 2.168a4 4 0 0 1 -5.8 4.217l-1.948-1.024a4 4 0 0 0 -3.725-.005z"
        fill="#e57373"
      />
      <circle cx="64" cy="91.377" fill="#fff9e2" r="4.129" />
      <path d="m38.625 116.438h50.75v9.813h-50.75z" fill="#8b818e" />
      <path d="m126.25 10.877h-124.5v-7.125l124.5-2.002z" fill="#8b818e" />
      <path
        d="m15.375 59.666a5.877 5.877 0 0 1 -1.898 4.334h7.854a5.877 5.877 0 0 0 1.9-4.334c0-4.449-4-4.449-4-8.9s4-4.446 4-8.891-4-4.448-4-8.9 4-4.445 4-8.891-4-4.445-4-8.889c0-2.415 1.18-3.518 2.259-4.735h-7.856c-1.079 1.217-2.259 2.32-2.259 4.735 0 4.444 4 4.444 4 8.889s-4 4.446-4 8.891 4 4.447 4 8.9-4 4.445-4 8.891 4 4.451 4 8.9z"
        fill="#f9e0a0"
      />
      <path
        d="m23.229 59.666a5.877 5.877 0 0 1 -1.898 4.334h7.854a5.881 5.881 0 0 0 1.9-4.334c0-4.449-4-4.449-4-8.9s4-4.446 4-8.891-4-4.448-4-8.9 4-4.445 4-8.891-4-4.445-4-8.889c0-2.415 1.18-3.518 2.259-4.735h-7.856c-1.079 1.217-2.259 2.32-2.259 4.735 0 4.444 4 4.444 4 8.889s-4 4.446-4 8.891 4 4.447 4 8.9-4 4.445-4 8.891 4 4.451 4 8.9z"
        fill="#fcefc8"
      />
      <path
        d="m31.083 59.666a5.881 5.881 0 0 1 -1.898 4.334h7.854a5.881 5.881 0 0 0 1.9-4.334c0-4.449-4-4.449-4-8.9s4-4.446 4-8.891-4-4.448-4-8.9 4-4.445 4-8.891-4-4.445-4-8.889c0-2.415 1.18-3.518 2.259-4.735h-7.856c-1.079 1.217-2.259 2.32-2.259 4.735 0 4.444 4 4.444 4 8.889s-4 4.446-4 8.891 4 4.447 4 8.9-4 4.445-4 8.891 4 4.451 4 8.9z"
        fill="#f9e0a0"
      />
      <g fill="#c6c1c8">
        <path d="m11.375 15.2c0-2.415 1.18-3.518 2.259-4.735h-11.884v7.125l10.211.164a5.361 5.361 0 0 1 -.586-2.554z" />
        <path d="m37.2 10.467c-1.079 1.217-2.259 2.32-2.259 4.735a5.282 5.282 0 0 0 .806 2.937l90.507 1.455v-9.127z" />
      </g>
    </g>
  </svg>
)

const Yakitori = () => (
  <svg viewBox="0 0 503.566 503.566" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path
          d="m354.836 113.399c-4.142 0-7.5-3.357-7.5-7.5v-98.399c0-4.143 3.358-7.5 7.5-7.5s7.5 3.357 7.5 7.5v98.399c0 4.143-3.358 7.5-7.5 7.5z"
          fill="#f6e2c4"
        />
      </g>
      <g>
        <path
          d="m149.074 103.39c-4.142 0-7.5-3.357-7.5-7.5v-88.39c0-4.143 3.358-7.5 7.5-7.5s7.5 3.357 7.5 7.5v88.39c0 4.142-3.358 7.5-7.5 7.5z"
          fill="#f6e2c4"
        />
      </g>
      <path
        d="m166.498 360.672v87.303l6.657 6.5c2.138 2.087 3.343 4.949 3.343 7.937v31.767c0 5.184-4.203 9.387-9.387 9.387h-24.073c-5.184 0-9.387-4.203-9.387-9.387v-133.507-75.578h31.175z"
        fill="#f6e2c4"
      />
      <path
        d="m372.26 360.672v87.303l6.657 6.5c2.138 2.087 3.343 4.949 3.343 7.937v31.767c0 5.184-4.203 9.387-9.387 9.387h-24.073c-5.184 0-9.387-4.203-9.387-9.387v-133.507-75.578h31.175z"
        fill="#f6e2c4"
      />
      <g>
        <path
          d="m400.019 133.122c-30.277 6.813-60.553 6.788-90.83-.073-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804 1.813-8.264 8.615-14.551 16.986-15.777 29.345-4.298 58.689-4.313 88.034-.047 8.508 1.237 15.386 7.707 17.062 16.139 3.376 16.984 3.467 33.72-.05 50.184-1.671 7.827-7.805 13.932-15.614 15.689z"
          fill="#e5bda7"
        />
        <path
          d="m334.189 119.296c-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804.242-1.104.58-2.17.992-3.197-3.002.35-6.004.733-9.006 1.173-8.372 1.226-15.173 7.513-16.986 15.777-3.774 17.197-3.876 34.141.057 50.804 1.807 7.654 7.861 13.573 15.531 15.311 30.276 6.861 60.553 6.886 90.83.073 6.399-1.44 11.668-5.802 14.285-11.636-26.705 4.586-53.41 3.861-80.115-2.19z"
          fill="#dfae91"
        />
        <path
          d="m400.019 223.413c-30.277 6.813-60.553 6.788-90.83-.073-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804 1.813-8.264 8.615-14.551 16.986-15.777 29.345-4.298 58.689-4.313 88.034-.047 8.508 1.237 15.386 7.707 17.062 16.139 3.376 16.984 3.467 33.72-.05 50.184-1.671 7.827-7.805 13.932-15.614 15.689z"
          fill="#f7d8a4"
        />
        <path
          d="m336.613 208.98c-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804.216-.983.507-1.936.858-2.858-3.765.411-7.53.889-11.295 1.44-8.372 1.226-15.173 7.513-16.986 15.777-3.774 17.197-3.876 34.141.057 50.804 1.807 7.654 7.861 13.573 15.531 15.311 30.276 6.861 60.553 6.885 90.83.073 6.481-1.458 11.813-5.921 14.393-11.869-25.929 4.154-51.872 3.313-77.8-2.563z"
          fill="#f5cd8b"
        />
        <path
          d="m400.019 355.185c-30.277 6.813-60.553 6.788-90.83-.073-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804 1.813-8.264 8.615-14.551 16.986-15.777 29.345-4.298 58.689-4.313 88.034-.047 8.508 1.237 15.386 7.707 17.062 16.139 3.376 16.984 3.467 33.72-.05 50.184-1.671 7.827-7.805 13.932-15.614 15.689z"
          fill="#e5bda7"
        />
        <path
          d="m336.613 342.901c-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.606-.057-50.804.398-1.812 1.047-3.521 1.888-5.109-4.108.434-8.217.942-12.325 1.544-8.372 1.226-15.173 7.513-16.986 15.777-3.774 17.197-3.876 34.141.057 50.804 1.807 7.654 7.861 13.573 15.531 15.311 30.276 6.861 60.553 6.885 90.83.073 5.602-1.26 10.345-4.765 13.195-9.531-25.53 3.936-51.073 3.031-76.602-2.754z"
          fill="#dfae91"
        />
        <path
          d="m420.244 228.505h-131.504c-11.455 0-20.74 9.286-20.74 20.74 0 11.455 9.286 20.74 20.74 20.74h131.504c11.455 0 20.74-9.286 20.74-20.74 0-11.455-9.286-20.74-20.74-20.74z"
          fill="#c5e97d"
        />
        <path
          d="m439.579 256.29h-131.504c-11.455 0-20.74-9.286-20.74-20.74 0-2.474.456-4.837 1.251-7.037-11.383.084-20.586 9.33-20.586 20.732 0 11.455 9.286 20.74 20.74 20.74h131.504c8.98 0 16.63-5.715 19.515-13.7-.052 0-.128.005-.18.005z"
          fill="#b1e254"
        />
        <path
          d="m416.763 73.377-22.058-.014c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5l23.465.011c-.171-4.978-.64-9.974-1.407-14.997z"
          fill="#dfae91"
        />
        <path
          d="m345.603 103.871-54.284-.02c.478 4.643 1.254 9.286 2.34 13.887.09.383.194.77.305 1.144l51.639-.012c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.499-7.5-7.499z"
          fill="#dfae91"
        />
        <g>
          <path
            d="m335.685 88.363h-9.184c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9.184c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
            fill="#dfae91"
          />
        </g>
        <path
          d="m290.858 188.024c.233 5.024.821 10.051 1.753 15.027l61.881-.014c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
          fill="#f5cd8b"
        />
        <path
          d="m416.719 163.396h-40.302c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h41.74c-.183-4.978-.662-9.978-1.438-15z"
          fill="#f5cd8b"
        />
        <path
          d="m337.355 295.419-44.997-.006c-.836 5.022-1.34 10.029-1.528 15.006h46.525c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
          fill="#dfae91"
        />
        <g>
          <path
            d="m349.444 339.712h-7.439c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h7.439c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
            fill="#dfae91"
          />
        </g>
        <path
          d="m368.912 313.148c0 4.143 3.358 7.5 7.5 7.5h41.682c.247-4.977.185-9.989-.181-15.012l-41.5.012c-4.143 0-7.501 3.358-7.501 7.5z"
          fill="#dfae91"
        />
      </g>
      <path
        d="m318.658 103.985c-.01-.041-.018-.083-.027-.124l-27.312-.01c.478 4.643 1.254 9.286 2.34 13.887.09.383.194.771.305 1.145l38.667-.009c-6.923-2.144-12.291-7.762-13.973-14.889z"
        fill="#d79c7a"
      />
      <path
        d="m321.082 193.67c-.443-1.876-.827-3.756-1.168-5.64l-29.056-.006c.233 5.024.821 10.051 1.753 15.027l33.563-.008c-2.449-2.575-4.243-5.779-5.092-9.373z"
        fill="#f4c67b"
      />
      <path
        d="m318.39 295.416-26.032-.003c-.836 5.022-1.34 10.029-1.528 15.006h27.631c-.36-4.978-.384-9.979-.071-15.003z"
        fill="#d79c7a"
      />
      <g>
        <g>
          <path
            d="m194.601 134.17c-30.277 6.813-60.553 6.788-90.83-.073-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804 1.813-8.264 8.615-14.551 16.986-15.777 29.345-4.298 58.689-4.313 88.034-.047 8.508 1.237 15.386 7.707 17.062 16.139 3.376 16.984 3.467 33.72-.05 50.184-1.67 7.827-7.805 13.932-15.614 15.689z"
            fill="#e5bda7"
          />
          <path
            d="m128.771 120.344c-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804.242-1.104.58-2.17.992-3.197-3.002.35-6.004.733-9.006 1.173-8.372 1.226-15.173 7.513-16.986 15.777-3.774 17.197-3.876 34.141.057 50.804 1.807 7.654 7.861 13.573 15.531 15.311 30.276 6.861 60.553 6.886 90.83.073 6.399-1.44 11.668-5.802 14.285-11.636-26.705 4.586-53.41 3.862-80.115-2.19z"
            fill="#dfae91"
          />
          <path
            d="m194.601 224.461c-30.277 6.813-60.553 6.788-90.83-.073-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804 1.813-8.264 8.615-14.551 16.986-15.777 29.345-4.298 58.689-4.313 88.034-.047 8.508 1.237 15.386 7.707 17.062 16.139 3.376 16.984 3.467 33.72-.05 50.184-1.67 7.827-7.805 13.932-15.614 15.689z"
            fill="#f7d8a4"
          />
          <path
            d="m131.195 210.028c-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804.216-.983.507-1.936.858-2.858-3.765.411-7.53.889-11.295 1.44-8.372 1.226-15.173 7.513-16.986 15.777-3.774 17.197-3.876 34.141.057 50.804 1.807 7.654 7.861 13.573 15.531 15.311 30.276 6.861 60.553 6.885 90.83.073 6.481-1.458 11.813-5.921 14.393-11.869-25.929 4.154-51.871 3.313-77.8-2.563z"
            fill="#f5cd8b"
          />
          <path
            d="m194.601 356.233c-30.277 6.813-60.553 6.788-90.83-.073-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.607-.057-50.804 1.813-8.264 8.615-14.551 16.986-15.777 29.345-4.298 58.689-4.313 88.034-.047 8.508 1.237 15.386 7.707 17.062 16.139 3.376 16.984 3.467 33.72-.05 50.184-1.67 7.827-7.805 13.932-15.614 15.689z"
            fill="#e5bda7"
          />
          <path
            d="m131.195 343.949c-7.67-1.738-13.724-7.657-15.531-15.311-3.934-16.664-3.831-33.606-.057-50.804.398-1.812 1.047-3.521 1.888-5.109-4.108.434-8.217.942-12.325 1.544-8.372 1.226-15.173 7.513-16.986 15.777-3.774 17.197-3.876 34.141.057 50.804 1.807 7.654 7.861 13.573 15.531 15.311 30.276 6.861 60.553 6.885 90.83.073 5.602-1.26 10.345-4.765 13.195-9.531-25.529 3.936-51.073 3.031-76.602-2.754z"
            fill="#dfae91"
          />
          <path
            d="m214.827 229.553h-131.505c-11.455 0-20.74 9.286-20.74 20.74 0 11.455 9.286 20.74 20.74 20.74h131.504c11.455 0 20.74-9.286 20.74-20.74.001-11.455-9.285-20.74-20.739-20.74z"
            fill="#c5e97d"
          />
          <path
            d="m234.161 257.338h-131.504c-11.455 0-20.74-9.286-20.74-20.74 0-2.474.456-4.837 1.251-7.037-11.383.084-20.586 9.33-20.586 20.732 0 11.455 9.286 20.74 20.74 20.74h131.504c8.98 0 16.63-5.715 19.515-13.7-.052 0-.127.005-.18.005z"
            fill="#b1e254"
          />
          <path
            d="m211.346 74.425-22.058-.014c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5l23.465.011c-.172-4.978-.641-9.974-1.407-14.997z"
            fill="#dfae91"
          />
          <path
            d="m140.185 104.919-54.284-.02c.478 4.643 1.254 9.286 2.34 13.887.09.383.194.77.305 1.144l51.639-.012c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.499-7.5-7.499z"
            fill="#dfae91"
          />
          <g>
            <path
              d="m130.267 89.411h-9.184c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h9.184c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#dfae91"
            />
          </g>
          <path
            d="m85.44 189.072c.233 5.024.821 10.051 1.753 15.027l61.881-.014c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
            fill="#f5cd8b"
          />
          <path
            d="m211.301 164.444h-40.301c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h41.74c-.184-4.977-.663-9.977-1.439-15z"
            fill="#f5cd8b"
          />
          <path
            d="m131.938 296.467-44.998-.007c-.836 5.022-1.34 10.029-1.528 15.007h46.525c4.142 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.499-7.5z"
            fill="#dfae91"
          />
          <g>
            <path
              d="m144.027 340.76h-7.439c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h7.439c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
              fill="#dfae91"
            />
          </g>
          <path
            d="m163.495 314.196c0 4.143 3.358 7.5 7.5 7.5h41.682c.247-4.977.185-9.989-.181-15.012l-41.501.012c-4.142 0-7.5 3.358-7.5 7.5z"
            fill="#dfae91"
          />
        </g>
      </g>
      <path
        d="m113.241 105.033c-.01-.041-.018-.083-.027-.124l-27.312-.01c.478 4.643 1.254 9.286 2.34 13.887.09.383.194.771.305 1.145l38.667-.009c-6.924-2.144-12.291-7.762-13.973-14.889z"
        fill="#d79c7a"
      />
      <path
        d="m115.665 194.718c-.443-1.876-.827-3.756-1.168-5.64l-29.056-.006c.233 5.024.821 10.051 1.753 15.027l33.563-.008c-2.45-2.575-4.244-5.779-5.092-9.373z"
        fill="#f4c67b"
      />
      <path
        d="m112.973 296.464-26.033-.004c-.836 5.022-1.34 10.029-1.528 15.006h27.631c-.36-4.977-.383-9.978-.07-15.002z"
        fill="#d79c7a"
      />
    </g>
  </svg>
)

const MenuItem = styled.div`
  display: flex;
  justify-content: center;
`

const IndexPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const { t, i18n } = useCustomTranslation([namespace, "common"])
  const title = t(`common:country.japan.card.food`)

  return (
    <>
      <SEO
        title={title}
        fullTitle={t("full-title")}
        socialNetworkDescription={t("social-network-description")}
        googleDescription={t("google-description")}
        image={HomeImgUrl}
        location={location}
      />
      <JapanBlogLayout page={id} location={location}>
        <JapanTitle title={title} linkId={id} />
        <ImageAsLandscape>
          <SharedCardJapanImages image="foodInJapan" />
        </ImageAsLandscape>
        <Quote>{t("quote")}</Quote>
        <Divider />
        <Introduction>{t("introduction.part1")}</Introduction>
        <SectionContent>
          <p>{t("introduction.part2")}</p>
          <p>{t("introduction.part3")}</p>
          <p>{t("introduction.part4")}</p>
          <p>{t("introduction.part5")}</p>
        </SectionContent>
        <Divider />
        <MenuArticle title={t("index")}>
          <MenuItem>
            <a href="#barbecue">{t("section1.title")}</a>&nbsp;
            <Barbecue />
          </MenuItem>
          <MenuItem>
            <Donburi />
            &nbsp;<a href="#donburi">{t("section2.title")}</a>
          </MenuItem>
          <MenuItem>
            <a href="#gyozas">{t("section3.title")}</a>&nbsp;
            <Gyozas />
          </MenuItem>
          <MenuItem>
            <Ramen />
            &nbsp;<a href="#ramen">{t("section4.title")}</a>
          </MenuItem>
          <MenuItem>
            <a href="#soba">{t("section5.title")}</a>&nbsp;
            <Soba />
          </MenuItem>
          <MenuItem>
            <Sushi />
            &nbsp;<a href="#sushis">{t("section6.title")}</a>
          </MenuItem>
          <MenuItem>
            <a href="#tempura">{t("section7.title")}</a>&nbsp;
            <Tempura />
          </MenuItem>
          <MenuItem>
            <Tonkatsu />
            &nbsp;<a href="#tonkatsu">{t("section8.title")}</a>
          </MenuItem>
          <MenuItem>
            <a href="#udon">{t("section9.title")}</a>&nbsp;
            <Udon />
          </MenuItem>
          <MenuItem>
            <Yakitori />
            &nbsp;<a href="#yakitori">{t("section10.title")}</a>
          </MenuItem>
          <MenuItem>
            <a href="#more">{t("section11.title")}</a>
          </MenuItem>
        </MenuArticle>
        <Divider />
        <Anchor id="barbecue" />
        <section>
          <JapanHeadline>{t("section1.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section1.part1")}</p>
            <p>{t("section1.part2")}</p>
            <p>{t("section1.part3")}</p>
            <p>{t("section1.part4")}</p>
            <p>{t("section1.part5")}</p>
            <GroupOfImages>
              <ImageAsLandscape>
                <FoodInJapanImages image="barbecue" />
              </ImageAsLandscape>
              <ImageAsPortrait>
                <FoodInJapanImages image="barbecue2" />
              </ImageAsPortrait>
              <ImageAsPortrait>
                <FoodInJapanImages image="barbecue3" />
              </ImageAsPortrait>
              <ImageAsLandscape>
                <FoodInJapanImages image="barbecue4" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="donburi" />
        <section>
          <JapanHeadline>{t("section2.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section2.part1")}</p>
            <p>{t("section2.part2")}</p>
            <p>{t("section2.part3")}</p>
            <p>{t("section2.part4")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Gyudon ⤵" titlePosition="top">
                <FoodInJapanImages image="donburi" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="donburi2" />
              </ImageAsLandscape>
              <ImageAsLandscape title="Katsudon ⤵" titlePosition="top">
                <FoodInJapanImages image="donburi3" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="donburi4" />
              </ImageAsLandscape>
              <ImageAsLandscape title="Chicken Teriyaki Don ⤵" titlePosition="top">
                <FoodInJapanImages image="donburi5" />
              </ImageAsLandscape>
              <TwoImagesSameSizeOrToGroup>
                <FoodInJapanImages image="donburi6" />
                <FoodInJapanImages image="donburi7" />
              </TwoImagesSameSizeOrToGroup>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="gyozas" />
        <section>
          <JapanHeadline>{t("section3.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section3.part1")}</p>
            <p>{t("section3.part2")}</p>
            <ul>
              <li>{t("section3.part3")}</li>
              <li>{t("section3.part4")}</li>
              <li>{t("section3.part5")}</li>
            </ul>
            <p>{t("section3.part6")}</p>
            <p>{t("section3.part7")}</p>
            <GroupOfImages>
              <ImageAsLandscape>
                <FoodInJapanImages image="gyozas" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="gyozas2" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="gyozas3" />
              </ImageAsLandscape>
              <ImageAsLandscapeOnTheLeft title="Gyoza Crispy ⤵" titlePosition="top">
                <FoodInJapanImages image="gyozas4" />
              </ImageAsLandscapeOnTheLeft>
              <ImageAsLandscapeOnTheRight title="Gyoza Original ⤵" titlePosition="top">
                <FoodInJapanImages image="gyozas5" />
              </ImageAsLandscapeOnTheRight>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="ramen" />
        <section>
          <JapanHeadline>{t("section4.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section4.part1")}</p>
            <p>{t("section4.part2")}</p>
            <p>{t("section4.part3")}</p>
            <ul>
              <li>{t("section4.part4")}</li>
              <li>{t("section4.part5")}</li>
              <li>{t("section4.part6")}</li>
              <li>{t("section4.part7")}</li>
            </ul>
            <p>{t("section4.part8")}</p>
            <GroupOfImages>
              <ImageAsLandscape>
                <FoodInJapanImages image="ramen" />
              </ImageAsLandscape>
              <ImageAsLandscapeOnTheLeft title="Chashu Ramen - Original Tonkotsu ⤵" titlePosition="top">
                <FoodInJapanImages image="ramen2" />
              </ImageAsLandscapeOnTheLeft>
              <ImageAsLandscapeOnTheRight title="Pork Chop Ramen - Original Tonkotsu ⤵" titlePosition="top">
                <FoodInJapanImages image="ramen3" />
              </ImageAsLandscapeOnTheRight>
              <ImageAsLandscape title="Shoyu Ajitama Ramen ⤵" titlePosition="top">
                <FoodInJapanImages image="ramen4" />
              </ImageAsLandscape>
              <ImageAsLandscape title="Beef Ramen - Shio Base ⤵" titlePosition="top">
                <FoodInJapanImages image="ramen5" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="soba" />
        <section>
          <JapanHeadline>{t("section5.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section5.part1")}</p>
            <p>{t("section5.part2")}</p>
            <p>{t("section5.part3")}</p>
            <p>{t("section5.part4")}</p>
            <p>{t("section5.part5")}</p>
            <GroupOfImages>
              <ImageAsLandscapeOnTheLeft title="Cha Soba - Chilled green tea noodles ⤵" titlePosition="top">
                <FoodInJapanImages image="soba" />
              </ImageAsLandscapeOnTheLeft>
              <ImageAsLandscapeOnTheRight>
                <FoodInJapanImages image="soba2" />
              </ImageAsLandscapeOnTheRight>
              <ImageAsLandscapeOnTheLeft title="Cha Soba + Chicken Teriyaki Salad ⤵" titlePosition="top">
                <FoodInJapanImages image="soba3" />
              </ImageAsLandscapeOnTheLeft>
              <ImageAsLandscapeOnTheRight>
                <FoodInJapanImages image="soba4" />
              </ImageAsLandscapeOnTheRight>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="sushis" />
        <section>
          <JapanHeadline>{t("section6.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section6.part1")}</p>
            <p>{t("section6.part2")}</p>
            <ul>
              <li>{t("section6.part3")}</li>
              <li>{t("section6.part4")}</li>
              <li>{t("section6.part5")}</li>
              <li>{t("section6.part6")}</li>
              <li>{t("section6.part7")}</li>
              <li>{t("section6.part8")}</li>
            </ul>
            <p>{t("section6.part9")}</p>
            <p>{t("section6.part10")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Salmon Sashimi ⤵" titlePosition="top">
                <FoodInJapanImages image="sushis" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="sushis2" />
              </ImageAsLandscape>
            </GroupOfImages>
            <p className="tc ttu">{t("section6.part11")}</p>
            <ol>
              <li>Gunkan : 2 Lobster Salad - Gunkan : 2 Salmon Avocado Mayo - Maki : 3 Salmon Maru</li>
              <li>
                Nigiri : 2 Tamago Sushi (Egg) - Nigiri : 2 Unagi Sushi (BBQ Eel) - Nigiri : 2 Tamago Zensai (crab stick,
                egg)
              </li>
              <li>
                Nigiri : 2 Salmon Sushi - Nigiri : 2 Maguro Sushi (Tuna) - Nigiri : 2 Salmon Cheese (Salmon, Cheese,
                Chilli Tomato Sauce)
              </li>
              <li>
                Maki : 1 + 2 Salmon Sakura (Salmon, avocado, flying fish roe, spicy cod roe mayo) - Maki : 6 Salmon Maki
                - Maki : 6 Tamago Maki (Egg) - Maki : 4 California (Crab Stick, Avocado, Cucumber, Egg, Shrimp roe,
                mayo)
              </li>
            </ol>
            <GroupOfImages>
              <ImageAsLandscapeOnTheLeft>
                <FoodInJapanImages image="sushis3" />
              </ImageAsLandscapeOnTheLeft>
              <ImageAsLandscapeOnTheRight>
                <FoodInJapanImages image="sushis4" />
              </ImageAsLandscapeOnTheRight>
              <ImageAsLandscape>
                <FoodInJapanImages image="sushis5" />
              </ImageAsLandscape>
              <ImageAsLandscapeOnTheLeft
                title="California Handroll (Rice, Cucumber, Crab Stick, Egg, Mayo, Shrimp Roe, Avocado)  -  Salmon Handroll (Rice, Salmon) ⤵"
                titlePosition="top"
              >
                <FoodInJapanImages image="sushis6" />
              </ImageAsLandscapeOnTheLeft>
              <ImageAsLandscapeOnTheRight>
                <FoodInJapanImages image="sushis7" />
              </ImageAsLandscapeOnTheRight>
              <ImageAsPortrait title="Maki with Soy Wrap ⤵" titlePosition="top">
                <FoodInJapanImages image="sushis8" />
              </ImageAsPortrait>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="tempura" />
        <section>
          <JapanHeadline>{t("section7.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section7.part1")}</p>
            <p>{t("section7.part2")}</p>
            <p>{t("section7.part3")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Shrimp Tempura ⤵" titlePosition="top">
                <FoodInJapanImages image="tempura" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="tempura2" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="tonkatsu" />
        <section>
          <JapanHeadline>{t("section8.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section8.part1")}</p>
            <p>{t("section8.part2")}</p>
            <p>{t("section8.part3")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Tonkatsu curry rice ⤵" titlePosition="top">
                <FoodInJapanImages image="curry" />
              </ImageAsLandscape>
              <ImageAsLandscapeOnTheLeft title="Chicken Katsu curry rice ⤵" titlePosition="top">
                <FoodInJapanImages image="curry2" />
              </ImageAsLandscapeOnTheLeft>
              <ImageAsLandscapeOnTheRight>
                <FoodInJapanImages image="curry3" />
              </ImageAsLandscapeOnTheRight>
              <ImageAsPortrait>
                <FoodInJapanImages image="curry4" />
              </ImageAsPortrait>
              <ImageAsLandscape>
                <FoodInJapanImages image="curry5" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="udon" />
        <section>
          <JapanHeadline>{t("section9.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section9.part1")}</p>
            <p>{t("section9.part2")}</p>
            <p>{t("section9.part3")}</p>
            <p>{t("section9.part4")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Chicken Katsu Don + Udon ⤵" titlePosition="top">
                <FoodInJapanImages image="udon" />
              </ImageAsLandscape>
              <ImageAsLandscape title="Niku Udon - Japanese noodles with black pepper beef ⤵" titlePosition="top">
                <FoodInJapanImages image="udon2" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="udon3" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="yakitori" />
        <section>
          <JapanHeadline>{t("section10.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <p>{t("section10.part1")}</p>
            <p>{t("section10.part2")}</p>
            <p>{t("section10.part3")}</p>
            <p>{t("section10.part4")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Yakitori + Rice ⤵" titlePosition="top">
                <FoodInJapanImages image="yakitori" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="yakitori2" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Anchor id="more" />
        <section>
          <JapanHeadline>{t("section11.title")}</JapanHeadline>
          <Divider />
          <SubTitleTransport>{t("section11.part1.title")}</SubTitleTransport>
          <SectionContent>
            <p>{t("section11.part1.part1")}</p>
            <p>{t("section11.part1.part2")}</p>
            <ImageAsLandscape>
              <FoodInJapanImages image="soup" />
            </ImageAsLandscape>
          </SectionContent>
          <Divider />
          <SubTitleTransport>{t("section11.part2.title")}</SubTitleTransport>
          <SectionContent>
            <p>{t("section11.part2.part1")}</p>
            <p>{t("section11.part2.part2")}</p>
            <p>{t("section11.part2.part3")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Grilled Salmon Onigiri ⤵" titlePosition="top">
                <FoodInJapanImages image="onigiri" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="onigiri2" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
          <Divider />
          <SubTitleTransport>{t("section11.part3.title")}</SubTitleTransport>
          <SectionContent>
            <p>{t("section11.part3.part1")}</p>
            <p>{t("section11.part3.part2")}</p>
            <p>{t("section11.part3.part3")}</p>
            <GroupOfImages>
              <ImageAsLandscape title="Chicken Katsu Onigirazu - Ebi Onigirazu ⤵" titlePosition="top">
                <FoodInJapanImages image="onigirazu" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="onigirazu2" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
          <Divider />
          <SubTitleTransport>{t("section11.part4.title")}</SubTitleTransport>
          <SectionContent>
            <p>{t("section11.part4.part1")}</p>
            <p>{t("section11.part4.part2")}</p>
            <p>{t("section11.part4.part3")}</p>
            <GroupOfImages>
              <ImageAsLandscape>
                <FoodInJapanImages image="teppanyaki" />
              </ImageAsLandscape>
              <ImageAsLandscape>
                <FoodInJapanImages image="teppanyaki2" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <section>
          <JapanHeadline>{t("section12.title")}</JapanHeadline>
          <Divider />
          <SectionContent>
            <FakeList>{t("section12.part1.title")}</FakeList>
            <p>{t("section12.part1.part1")}</p>
            <p>{t("section12.part1.part2")}</p>
            <FakeList>{t("section12.part2.title")}</FakeList>
            <p>{t("section12.part2.part1")}</p>
            <p>{t("section12.part2.part2")}</p>
            <GroupOfImages>
              <ImageAsLandscape>
                <FoodInJapanImages image="fakeFood" />
              </ImageAsLandscape>
              <TwoImagesSameSizeOrToGroup>
                <FoodInJapanImages image="fakeFood2" />
                <FoodInJapanImages image="fakeFood3" />
              </TwoImagesSameSizeOrToGroup>
              <ImageAsLandscape>
                <FoodInJapanImages image="fakeFood4" />
              </ImageAsLandscape>
            </GroupOfImages>
          </SectionContent>
        </section>
        <Divider />
        <Conclusion>
          <p>{t("conclusion")}</p>
          <ul>
            <li>{t("question1")}</li>
            <li>{t("question2")}</li>
          </ul>
        </Conclusion>
        <Divider />
        <Comments
          collectionName={namespace}
          location={location}
          facebookQuote={`${t("facebook.part1")}\n${t("facebook.part2")}`}
          pinterest={{
            description: t("pinterest"),
            nodes:
              i18n.languageCode === "fr"
                ? [<FoodInJapanImages image="cardFr1" key="cardFr1" />]
                : [<FoodInJapanImages image="cardEn1" key="cardEn1" />],
          }}
        />
      </JapanBlogLayout>
    </>
  )
}

export default IndexPage
